import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { catchError, filter, map, pluck, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subscribable, timer } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import qs from 'qs';
import { formatListing } from 'src/app/shared/utils-listing';
import { ListingStepsConfig, getListingsStepsField } from 'src/app/config/listing-steps';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  IListingStepsNames,
  ISellerListing,
  ISellerListingDisplayTypes,
  ISellerListingTitleAttachmentFile,
  IUser,
  ListingCategoryTypes,
} from '../../models';
import { IExtendedFilters, IFilters } from './listing.service';
import { ListingStepsAccordionService } from './listing-steps-accordion.service';

interface IModifyListingResponse {
  data: ISellerListing;
  message: string;
}
interface IShowLeavePageModal {
  show: boolean;
  route: string;
}

interface AttachTitleRequest {
  listingId: string;
  options: {
    type?: string;
    file?: ISellerListingTitleAttachmentFile;
    isComplete?: boolean;
  };
}

interface ShareTitleRequest {
  listingId: string;
  options: {
    type: string;
    deals: string[];
  };
}

export interface GetUsersInteractedWithListingUser extends IUser {
  chatId: string;
}

interface GetUsersInteractedWithListingResponse {
  users: GetUsersInteractedWithListingUser[];
}

interface GetFavoriteOffsiteListingsResponse {
  data: Pick<
    ISellerListing,
    '_id' | 'RegistrationYear' | 'CarMake' | 'CarModel' | 'Trim' | 'uploadImages' | 'vinNumber'
  >[];
}

const HEADER_HIDE_LOADER = 'X-No-Loader';
@Injectable({
  providedIn: 'root',
})
export class SellerListingService {
  savedFiltersSubject = new BehaviorSubject<IFilters[]>([]);

  constructor(
    private readonly accordionStepsService: ListingStepsAccordionService,
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly sanitizer: DomSanitizer
  ) {}

  private readonly showLeavePageModalSubject = new BehaviorSubject<IShowLeavePageModal>({
    show: false,
    route: null,
  });

  get showLeavePageModal() {
    return this.showLeavePageModalSubject.asObservable();
  }

  get savedFilters(): Observable<IFilters[]> {
    return this.savedFiltersSubject.asObservable();
  }

  private readonly showUnfinishedListingUi = new BehaviorSubject<boolean>(false);

  get getShowUnfinishedListingUi() {
    return this.showUnfinishedListingUi.asObservable();
  }

  private readonly isListingFreshSubject = new BehaviorSubject<boolean>(true);
  get isListingFresh() {
    return this.isListingFreshSubject.asObservable();
  }

  private readonly sellerListingsSubject = new BehaviorSubject<ISellerListing[]>(null);
  get sellerListings() {
    return this.sellerListingsSubject.asObservable().pipe(filter(Boolean)) as Observable<ISellerListing[]>;
  }

  private readonly listingStepsItemSubject = new BehaviorSubject<ISellerListing>(null);
  get listingStepsItem() {
    return this.listingStepsItemSubject.asObservable().pipe(filter(Boolean)) as Observable<ISellerListing>;
  }

  private readonly fieldsConfigSubject = new BehaviorSubject<ListingStepsConfig>(null);
  fieldsConfig = this.fieldsConfigSubject.asObservable().pipe(filter(Boolean)) as Observable<ListingStepsConfig>;

  set currentListing(listing: ISellerListing) {
    this.setListingStepsItem(formatListing(listing));
  }

  setListingStepsItem(listing: ISellerListing) {
    this.setListingStepsField(listing?.vehicleType);
    return this.listingStepsItemSubject.next(listing);
  }

  setListingStepsField(type: ListingCategoryTypes = ListingCategoryTypes.Auto) {
    const config = getListingsStepsField(type);
    this.fieldsConfigSubject.next(config);
  }

  updateItemInListingsList(changedListing) {
    const updatedList = this.sellerListingsSubject.value.map((listing) => {
      if (listing._id === changedListing._id) {
        return changedListing;
      }
      return listing;
    });
    this.sellerListingsSubject.next(updatedList);
  }

  renew(id): Subscribable<{ data: any; message: any }> {
    return this.http.put(`${this.config.apiUrl}/seller-listings/${id}/renew`, {}).pipe(
      map((res: { data: ISellerListing }) => {
        const listing = formatListing(res.data);
        return { data: listing };
      })
    );
  }

  retrieveSellerListing(data) {
    return this.http.get(`${this.config.apiUrl}/seller-listings`, data);
  }

  getListingMarketValue(id) {
    let headers = new HttpHeaders();
    headers = headers.set(HEADER_HIDE_LOADER, '1');

    return this.http.get(`${this.config.apiUrl}/seller-listings/${id}/market-value`, { headers });
  }

  getVehicleRetailPrice(slug: string, trim = null) {
    return this.http.get(
      `${this.config.apiUrl}/seller-listings/${slug}/retail-price${trim ? `?${qs.stringify({ trim })}` : ''}`
    );
  }

  getVehicleRetailPriceByVin(vin, trim = null) {
    return this.http.get(
      `${this.config.apiUrl}/vehicles/retail-price/${vin}${trim ? `?${qs.stringify({ trim })}` : ''}`
    );
  }

  deletePendingListing(id: string) {
    return this.http.delete(`${this.config.apiUrl}/seller-listings/${id}`);
  }

  setIsListingFresh(value: boolean) {
    this.isListingFreshSubject.next(value);
  }

  setShowLeavePageModal(value) {
    this.showLeavePageModalSubject.next(value);
  }

  setShowUnfinishedListingUi(value) {
    this.showUnfinishedListingUi.next(value);
  }

  getListingStepsById(
    id: string,
    activeStep: IListingStepsNames = null,
    resetPage = false,
    hideLoaderInterceptor = false
  ) {
    return this.getListingById(id, hideLoaderInterceptor, 'user').pipe(
      tap((res) => {
        const { data: listing } = res;
        this.setListingStepsItem(listing);
        if (activeStep) {
          this.accordionStepsService.setActivePanel(activeStep);
          resetPage = false;
        }

        if (resetPage) {
          this.accordionStepsService.setActivePanel(activeStep);
        }
      })
    );
  }

  getListingById(id, hideLoaderInterceptor = false, own = '', contact = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http
      .get(`${this.config.apiUrl}/seller-listings/${id}`, {
        headers,
        params: { own, ...(contact && { contact: `${contact}` }) },
      })
      .pipe(
        map((res: { data: ISellerListing }) => {
          const listing = formatListing(res.data);
          return { data: listing };
        })
      );
  }

  getListingBySlug(slug): Observable<ISellerListing> {
    return this.http
      .get<{ data: ISellerListing }>(`${this.config.apiUrl}/seller-listings/slug/${slug}`)
      .pipe(pluck('data'));
  }

  getSimilarListings(id) {
    return this.http.get(`${this.config.apiUrl}/seller-listings/${id}/similar`);
  }

  // get MyListings by ID
  getSellerListingsByUserId(hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http.get(`${this.config.apiUrl}/garage`, { headers }).pipe(
      map((res: { data: ISellerListing[] }) => {
        res.data = res.data.map((listing) => formatListing(listing));
        return res;
      })
    );
  }

  retrieveSellerListings(hideLoaderInterceptor = false): Observable<ISellerListing[]> {
    return this.getSellerListingsByUserId(hideLoaderInterceptor).pipe(
      map((sellerListings: { data: ISellerListing[] }) => {
        this.sellerListingsSubject.next(sellerListings.data);
        return sellerListings.data;
      }),
      catchError(() => {
        this.sellerListingsSubject.next(null);
        return of(null);
      })
    );
  }

  // update vehicles listings in seller profile
  updateSellerListing(data: ISellerListing, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http
      .put(`${this.config.apiUrl}/seller-listings/${data._id}`, data, {
        headers,
      })
      .pipe(
        tap((res: IModifyListingResponse) => {
          const updatedListing = formatListing(res.data);
          this.setListingStepsItem(updatedListing);
        })
      ) as Observable<IModifyListingResponse>;
  }

  createSellerListing(data: ISellerListing, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http
      .post(`${this.config.apiUrl}/seller-listings/`, data, {
        headers,
      })
      .pipe(
        tap((res: IModifyListingResponse) => {
          const newListing = formatListing(res.data);
          const currentListings = this.sellerListingsSubject.getValue();
          const listings = [newListing, ...(currentListings || [])];
          this.sellerListingsSubject.next(listings);
        })
      ) as Observable<IModifyListingResponse>;
  }

  createOrUpdateSellerListing(data, hideLoaderInterceptor = false) {
    return data._id
      ? this.updateSellerListing(data, hideLoaderInterceptor)
      : this.createSellerListing(data, hideLoaderInterceptor);
  }

  getUrl(listing, absolute = true) {
    const url = `/listing/${listing.slug}`;
    if (!absolute) {
      return url;
    }

    return `${this.config.appUrl}${url}`;
  }

  clearListingCache() {
    this.sellerListingsSubject.next([]);
  }

  attachTitle(data: AttachTitleRequest) {
    const { listingId, options } = data;
    return this.http.post(`${this.config.apiUrl}/seller-listings/${listingId}/titles/attach`, { ...options });
  }

  shareTitle(data: ShareTitleRequest) {
    const { listingId, options } = data;
    return this.http.post(`${this.config.apiUrl}/seller-listings/${listingId}/titles/share`, { ...options });
  }

  getUsersInteractedWithListing(listingId: string) {
    return this.http
      .get<GetUsersInteractedWithListingResponse>(`${this.config.apiUrl}/seller-listings/${listingId}/users-inquired`)
      .pipe(pluck('users'));
  }

  addFilter(filters: IExtendedFilters) {
    return this.http.put(`${this.config.apiUrl}/seller-listings/filters`, filters);
  }

  updateSavedFilters() {
    this.http.get<IExtendedFilters[]>(`${this.config.apiUrl}/seller-listings/filters`).subscribe((filters) => {
      this.savedFiltersSubject.next(filters);
    });
  }

  deleteFilter(id: string) {
    return this.http.delete(`${this.config.apiUrl}/seller-listings/filters?filterId=${id}`);
  }

  isListingLoanable(listing: ISellerListing) {
    const { RegistrationYear, Mileage = 0, TitleType, displayType, originalSourceInfo } = listing;
    const acceptableYear = new Date().getFullYear() - 6;
    const isDealNow = displayType === ISellerListingDisplayTypes.Private;
    const isOffsite = !!originalSourceInfo?.sourceSite;
    const isTitleClear = isDealNow || isOffsite || TitleType === 'Clear';
    return RegistrationYear >= acceptableYear && Mileage < 125000 && isTitleClear;
  }

  getEmbedUrl(url: string): SafeResourceUrl | null {
    const validYoutubeURL = (url && url.includes('youtube')) || url.includes('youtu.be');
    if (validYoutubeURL) {
      let videoId: string | null = null;
      if (url.includes('.be/')) {
        videoId = url.split('.be/')[1];
      } else if (url.includes('watch?v=')) {
        videoId = url.split('watch?v=')[1];
      } else if (url.includes('shorts')) {
        videoId = url.split('shorts/')[1];
      }

      if (videoId) {
        const iframeUrl = `https://www.youtube.com/embed/${videoId}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
      }
    }
    return null;
  }

  getFavoriteOffsiteListings() {
    return this.http
      .get<GetFavoriteOffsiteListingsResponse>(`${this.config.apiUrl}/seller-listings/off-site-favorites`)
      .pipe(pluck('data'));
  }
}
