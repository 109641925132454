<app-modal #modal [modalConfig]="modalConfig">
  <div customHeader class="header d-flex align-items-center justify-content-between p-4">
    @if (isAutofilled && verificationStep === 'verification-edit') {
    <button style="z-index: 10" (click)="onBack()">
      <img src="assets/back-arrow.svg" />
    </button>
    }
    <div class="title">Business verification</div>

    <button class="close ms-auto" (click)="skipVerification()">
      <img src="assets/close.svg" alt="" />
    </button>
  </div>
  <div class="body">
    <div class="inner-container">
      @if (verificationStep === 'verification-start') {
      <app-start-business-verification
        (onPrefillresponse)="handlePrefillResponse($event)"
      ></app-start-business-verification>
      } @if (verificationStep === 'verification-review') {
      <app-review-business-information
        (onEdit)="onEditInformation()"
        (onContinue)="onSubmitWithoutChanges()"
        [form]="businessIdentityForm"
        [businessIdentities]="businessIdentities"
        [selectedBusiness]="selectedBusiness"
      ></app-review-business-information>
      } @if (verificationStep === 'verification-edit') {
      <app-edit-business-information
        [showError]="showManualEntryError"
        (onContinue)="onSubmitIdentityForm()"
        [form]="businessIdentityForm"
      ></app-edit-business-information>
      } @if (verificationStep === 'verification-result') {
      <app-business-verification-result
        (onContinue)="closeModal()"
        [isSuccess]="isVerificationSucceeded"
      ></app-business-verification-result>
      }
    </div>
  </div>
</app-modal>
