@if (showNav && !showBottomNav) {
<nav class="page-header navbar navbar-expand-lg navbar-light" aria-label="top-nav">
  <button
    class="me-auto"
    type="button"
    aria-label="Toggle navigation"
    (click)="changeIsMenuCollapsed()"
    [ngClass]="{ 'expanded': isMenuCollapsed }"
  >
    <div class="icon"></div>
  </button>
  <div class="logo mx-auto">
    <a class="no-toggle" routerLink="/search-listings" (click)="isMenuCollapsed = true"
      ><img src="assets/logo-white.svg" alt="PrivateAuto"
    /></a>
  </div>
  <div #userMobile class="user ms-auto">
    <a class="no-toggle" routerLink="/account" (click)="isMenuCollapsed = true">
      <app-profile-photo
        [src]="user?.userDetails.profileImage"
        size="40"
        [profileName]="user?.userDetails.shortName"
      ></app-profile-photo>
      @if (isUserVerified) {
      <div class="notification verified"></div>
      } @if (!isUserVerified) {
      <div class="notification" [ngClass]="isUserPartiallyVerified ? 'partially-verified' : ''"></div>
      }
    </a>
    <app-popover-get-fully-verified
      [user]="user"
      [(isOpen)]="showGetFullyVerifiedPopover"
      [ref]="userMobile"
    ></app-popover-get-fully-verified>
  </div>
</nav>
}

<div class="page-body d-flex" [ngClass]="{ 'show-bottom-nav': showBottomNav, 'hide-nav':!showNav }">
  <!-- Standard nav for large devices and top nav -->
  @if (showNav) {
  <div
    [ngClass]="{'page-nav':showNav, 'd-none':showBottomNav}"
    class="pt-3 px-1 collapse d-md-block"
    [ngbCollapse]="isMenuCollapsed"
  >
    <section class="header align-items-center d-flex mb-4">
      <div class="logo">
        <a routerLink="/search-listings"><img src="assets/logo-white.svg" alt="PrivateAuto" /></a>
      </div>
      <div #userDesktop class="user ms-auto">
        @if (this.user?._id) {
        <a routerLink="/account">
          <app-profile-photo
            [src]="user?.userDetails.profileImage"
            size="40"
            [profileName]="user?.userDetails.shortName"
          ></app-profile-photo>
          @if (isUserVerified) {
          <div class="notification verified"></div>
          } @if (!isUserVerified) {
          <div class="notification" [ngClass]="isUserPartiallyVerified ? 'partially-verified' : ''"></div>
          }
        </a>
        }
        <app-popover-get-fully-verified
          [user]="user"
          [(isOpen)]="showGetFullyVerifiedPopover"
          [ref]="userDesktop"
        ></app-popover-get-fully-verified>
      </div>
    </section>
    <div #scrollNav class="scrollable">
      @if (this.user?._id) {
      <section class="funds position-relative" (click)="gotoEzPaySetup()" (onKeyDown)="gotoEzPaySetup();">
        <div class="top-teal p-0"></div>
        <div class="d-flex p-3">
          <div class="ms-3 w-100">
            <div class="buying-power">
              <a> My money </a>
            </div>
            <div class="amount d-flex align-items-center justify-content-between">
              <span class="text-break"> {{account?.account_available_balance || 0 | currency:'USD' }} </span>
              <div class="d-flex">
                @if (financeDisconnected;) {
                <img src="assets/warning-orange.svg" alt="warning" class="ms-2" />
                } @if (!financeDisconnected && hasInvalidBank) {
                <img src="assets/warning.svg" alt="warning" class="ms-2" />
                }
              </div>
            </div>
            <div class="money d-flex align-items-center justify-content-end">
              <img src="assets/autoPay.svg" alt="Money icon" />
            </div>
          </div>
        </div>
      </section>
      }
      <div class="menu-list px-2">
        <ul class="list-group">
          @for (item of menu; track item) {
          <li class="list-group-item" [ngClass]="{'background-stick' : currentUrl.includes(item.link)}">
            <a
              routerLink="{{item.link}}"
              class="align-items-center d-flex px-3"
              (click)="ChangeListSelected(item.text)"
              [ngClass]="{'background-stick' : currentUrl.includes(item.link)}"
            >
              <div class="icon">
                <img
                  src="{{item.icon}}"
                  class="text-danger"
                  alt="{{item.text}}"
                  [ngClass]="currentUrl.includes(item.link) ? '' : 'white-icon'"
                />
              </div>
              <div class="text">{{item.text}}</div>
              @if (item.error) {
              <img class="mt-1 ms-1" src="assets/chat-down-nav-icon.svg" alt="Chat down icon" />
              } @if (item.notifications) {
              <div class="ms-auto notification" [ngClass]="{'bg-black': item.text==='Favorites'}">
                {{item.notifications }}
              </div>
              } @if (item.addNew && !listings.length) {
              <a routerLink="/listing/steps" class="new ms-auto p-0">+ NEW</a>
              }
            </a>
          </li>
          }
        </ul>
      </div>
      <div #footer class="footer px-3 pb-2">
        <div class="menu ps-2">
          <ul class="ps-0">
            <li class="pb-3">
              <a class="no-toggle align-self-center d-flex" routerLink="/help-center">
                <img src="assets/help-circle.svg" alt="Help" />
                <span>Help center</span>
              </a>
            </li>
            <li class="pb-3">
              @if (this.user?._id) {
              <a class="align-self-center d-flex" (click)="logout()">
                <img src="assets/log-out.svg" alt="Logout" />
                <span>Logout</span>
              </a>
              } @if (!this.user?._id) {
              <a [routerLink]="['/auth/login']" class="align-self-center d-flex">
                <span>Sign In</span>
              </a>
              }
            </li>
          </ul>
        </div>
        <div class="sub-menu" name="bottomOfPage">
          <span>
            <a [href]="websiteUrl+'/terms'" target="_blank" rel="noopener">Terms</a>
          </span>
          <span>|</span>
          <span>
            <a [href]="websiteUrl+'/privacy'" target="_blank" rel="noopener">Privacy</a>
          </span>
        </div>
      </div>
    </div>
  </div>
  }

  <!-- Bottom nav menu, shown when expanding from bottom nav -->
  @if (showNav && showBottomNav) {
  <div
    class="pt-3 px-1 collapse d-md-none bottom-nav-menu"
    [ngClass]="{'page-nav':showNav}"
    [ngbCollapse]="isMenuCollapsed"
  >
    <a class="align-items-center d-flex mb-4 account" routerLink="/account">
      <div #userDesktop class="user">
        @if (this.user?._id) {
        <a routerLink="/account">
          <app-profile-photo
            [src]="user?.userDetails.profileImage"
            size="40"
            [profileName]="user?.userDetails.shortName"
          ></app-profile-photo>
          @if (isUserVerified) {
          <div class="notification verified"></div>
          } @if (!isUserVerified) {
          <div class="notification" [ngClass]="isUserPartiallyVerified ? 'partially-verified' : ''"></div>
          }
        </a>
        }
        <app-popover-get-fully-verified
          [user]="user"
          [(isOpen)]="showGetFullyVerifiedPopover"
          [ref]="userDesktop"
        ></app-popover-get-fully-verified>
      </div>
      <div class="ms-2">
        <div class="nav-name">{{user.userDetails.shortName}}.</div>
        <div class="nav-email">{{user.userDetails.email}}</div>
      </div>
      <div class="ms-auto nav-edit">Edit profile</div>
    </a>
    <div #scrollNav class="scrollable">
      @if (this.user?._id) {
      <section class="funds position-relative bottom-funds" (click)="gotoEzPaySetup()">
        <div class="top-teal p-0"></div>
        <div class="d-flex p-3">
          <div class="ms-3 w-100">
            @if (isUserVerified) {
            <div class="buying-power">
              <a> My money </a>
            </div>
            <div class="amount d-flex align-items-center justify-content-between">
              <span class="text-break"> {{account?.account_available_balance || 0 | currency:'USD' }} </span>
              <div class="d-flex">
                @if (financeDisconnected;) {
                <img src="assets/warning-orange.svg" alt="warning" class="ms-2" />
                } @if (!financeDisconnected && hasInvalidBank) {
                <img src="assets/warning.svg" alt="warning" class="ms-2" />
                }
              </div>
            </div>
            } @else {
            <div class="bank-setup">
              <div class="bank-empty-1"></div>
              <div class="bank-empty-2"></div>
              Set up my bank
            </div>
            }
            <div class="money d-flex align-items-center justify-content-end">
              <img src="assets/autoPay.svg" alt="Money icon" />
            </div>
          </div>
        </div>
      </section>
      } @if (!user.verification.isVerified) {
      <section class="verification-section p-3 mt-3" routerLink="/account">
        <div title class="verified-title">Verification</div>
        <div body class="verified-body">
          <div class="description mb-2">Gain trust and transact safely.</div>
          <app-progress-bar-steps [steps]="steps"></app-progress-bar-steps>
        </div>
      </section>
      }
      <div class="menu-list px-2">
        <div class="nav-title px-3">Buying and selling</div>
        <ul class="list-group">
          @for (item of bottomMenu; track item) {
          <li class="list-group-item bottom-nav-item">
            <a routerLink="{{item.link}}" class="align-items-center d-flex px-3">
              <div class="icon">
                <img src="{{item.icon}}" class="text-danger" alt="{{item.text}}" />
              </div>
              <div class="menu-text">{{item.text}}</div>
              @if (item.notifications) {
              <div class="ms-auto notification" [ngClass]="{'bg-black': item.text==='Favorites'}">
                {{item.notifications }}
              </div>
              } @if (item.addNew && !listings.length) {
              <a routerLink="/listing/steps" class="new ms-auto p-0">+ LIST A VEHICLE</a>
              }
              <img
                [ngClass]="{'ms-auto': !item.addNew || listings.length}"
                src="/assets/icons/nav-arrow.svg"
                alt="arrow"
              />
            </a>
          </li>
          }
        </ul>
        <div class="nav-title px-3">Account</div>
        <ul class="list-group">
          <li class="list-group-item bottom-nav-item">
            <a routerLink="/account" class="align-items-center d-flex px-3">
              <div class="icon">
                <img src="/assets/icons/nav-profile.svg" class="text-danger" alt="profile" />
              </div>
              <div class="menu-text">Profile</div>
              <img class="ms-auto" src="/assets/icons/nav-arrow.svg" alt="arrow" />
            </a>
          </li>
          <li class="list-group-item bottom-nav-item">
            <a class="align-items-center d-flex px-3" routerLink="/help-center">
              <div class="icon">
                <img src="/assets/icons/nav-support.svg" class="text-danger" alt="support" />
              </div>
              <div class="menu-text">Support</div>
              <img class="ms-auto" src="/assets/icons/nav-arrow.svg" alt="arrow" />
            </a>
          </li>
          <li class="list-group-item bottom-nav-item">
            <a class="align-items-center d-flex px-3" (click)="logout()">
              <div class="icon">
                <img src="/assets/icons/nav-logout.svg" class="text-danger" alt="support" />
              </div>
              <div class="menu-text">Log out</div>
              <img class="ms-auto" src="/assets/icons/nav-arrow.svg" alt="arrow" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  }

  <div
    #scrollContainer
    (scroll)="onScroll($event)"
    class="page-content"
    id="page-content"
    [ngClass]="{'nav-margin': showNav && !showBottomNav}"
    [style.background-color]="getCurrentRoute() === '/account/money' ? '#F7F7F7' : null"
  >
    <ng-content></ng-content>
  </div>
</div>

@if (showNav && showBottomNav) {
<nav class="bottom-navbar d-flex justify-content-around" aria-label="bottom-nav">
  <a class="item" routerLink="/search-listings" [ngClass]="{'selected' : currentUrl.includes('/search-listings')}">
    <img src="/assets/icons/nav-search.svg" alt="search" />Marketplace</a
  >
  <a class="item" routerLink="/services" [ngClass]="{'selected' : currentUrl.includes('/services')}">
    <img src="/assets/icons/nav-service.svg" alt="service" />Services</a
  >
  <a class="item" routerLink="/deals" [ngClass]="{'selected' : currentUrl.includes('/deals')}">
    <img src="/assets/icons/nav-deal.svg" alt="deal" />Deals</a
  >
  <a class="item" routerLink="/inbox" [ngClass]="{'selected' : currentUrl.includes('/inbox')}">
    <div class="d-flex flex-column position-relative">
      <img src="/assets/icons/nav-chat.svg" alt="search" />Chat@if (chatNotification) {
      <span class="notification-dot"></span>
      }
    </div>
  </a>
  <a
    class="item no-toggle"
    (click)="changeIsMenuCollapsed()"
    [ngClass]="{'selected-text' : currentUrl.includes('/menu')}"
  >
    <div #userMobile class="user">
      <div class="no-toggle">
        <app-profile-photo
          [src]="user?.userDetails.profileImage"
          size="40"
          [profileName]="user?.userDetails.shortName"
        ></app-profile-photo>
        <div class="notification menu"></div>
      </div>
    </div>
    Menu
  </a>
</nav>
} @if (isGlobalLoading) {
<div class="loading" [ngClass]="{'bottom-nav-loading':showBottomNav}">
  <div class="loading-inner">
    <div class="loading-image"><img src="assets/PA-logo3.gif" alt="" /></div>
  </div>
</div>
}

<app-modal-failed-pay-later [(isOpen)]="showFailedPayLaterModal" [unpaidListings]="failedPayLaterListings">
</app-modal-failed-pay-later>

<app-modal-set-display-name [(isOpen)]="showEnterDisplayName"></app-modal-set-display-name>
<app-prove-verification-modal
  #verificationModal
  (onSkipVerification)="skipVerification()"
  [(isOpen)]="showProveVerificationModal"
></app-prove-verification-modal>

<app-verification-welcome-modal
  [user]="user"
  #welcomeBusinessVerificationModal
  [(isOpen)]="showWelcomeBusinessVerificationModal"
  (onIndividualVerification)="initProveVerificationModal($event)"
  (onBusinessVerification)="initBusinessAccountVerificationModal($event)"
></app-verification-welcome-modal>

<app-business-verification-modal
  #businessVerificationModal
  [(isOpen)]="showBusinessVerificationModal"
></app-business-verification-modal>

<app-business-information-modal
  #businessInformationModal
  [(isOpen)]="showBusinessInformationModal"
></app-business-information-modal>

<app-invite-business-owner-modal
  #inviteBusinessOwnerModal
  [(isOpen)]="showInviteBusinessOwnerModal"
></app-invite-business-owner-modal>
