<app-modal #modal [modalConfig]="modalConfig">
  <div class="d-flex justify-content-center">
    <img alt="green-check" src="/assets/verified.svg" class="check-size" />
  </div>
  <div class="text-center">
    <div class="header mb-4">Purchase complete!</div>

    <div class="warning-text">
      These reports can be found in the service hub or when you view this listing while logged in.
    </div>

    @if (listing) {
    <div class="listing-details-header d-flex align-items-center mt-4 text-start">
      <div class="listing-image">
        <img src="/assets/clipboard.svg" alt="clipboard" />
      </div>
      <div class="listing-details">
        <div class="listing-main-text">{{listing.RegistrationYear}} {{listing.CarMake}} {{listing.CarModel}}</div>
        <div class="listing-sub-text">
          <div class="d-inline-block">VIN: {{ listing.vinNumber }}</div>
        </div>
      </div>
    </div>
    }

    <button class="btn btn-outline-primary w-100 mt-4 p-2" (click)="setViewReport('history')">
      View History Report
    </button>

    <button class="btn btn-outline-primary w-100 mt-3 p-2" (click)="setViewReport('market')">View Market Report</button>
  </div>
</app-modal>
