import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import { NotificationService, OnboardingService, UsersService } from 'src/app/core/services';
import { BusinessIdentity, ProveBusinessVerificationModel } from 'src/app/models/prove-verification.model';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { tap } from 'rxjs';

enum VerificationStep {
  VerificationStart = 'verification-start',
  VerificationReview = 'verification-review',
  VerificationEdit = 'verification-edit',
  VerificationResult = 'verification-result',
}
@AutoUnsubscribe()
@Component({
  selector: 'app-business-verification-modal',
  templateUrl: './business-verification-modal.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class BusinessVerificationModal implements OnInit, OnDestroy {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter();
  @Output() onSkipVerification = new EventEmitter();

  onboardingSession: string;
  verificationStep: VerificationStep;

  businessIdentityForm: FormGroup;

  isManualEntry = false;
  showManualEntryError = false;
  isAutofilled = false;
  isVerificationSucceeded = false;
  initialBusinessIdentityForm;

  businessIdentities: BusinessIdentity[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly onboardingService: OnboardingService,
    private readonly notificationService: NotificationService,
    private readonly usersService: UsersService
  ) {
    this.businessIdentityForm = this.fb.group({
      currentBusinessId: [null, []],
      businessName: [null, [Validators.required]],
      address: [null, [Validators.required]],
      city: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      region: [null, [Validators.required]],
      taxId: [null, [Validators.required]],
    });
  }

  modalConfig: ModalConfig = {};
  ngOnInit(): void {
    this.modalConfig = {
      hideHeader: true,
      modalDialogClass: 'prove-verification-modal',
      name: 'Prove verification modal',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.verificationStep = null;
        this.isManualEntry = false;
        this.showManualEntryError = false;
        this.onboardingService.setVerifyBusinessModalVisibility(false);
        return true;
      },
    };
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  onManualEntry() {
    this.isManualEntry = true;
    this.verificationStep = VerificationStep.VerificationEdit;
  }

  handlePrefillResponse(response: { success: boolean; data: ProveBusinessVerificationModel }) {
    if (response.success) {
      this.initIdentityForm(response.data);
      this.isAutofilled = true;
      this.verificationStep = VerificationStep.VerificationReview;
      return;
    }
    this.verificationStep = VerificationStep.VerificationEdit;
    this.showManualEntryError = true;
  }
  closeModal() {
    this.modalComponent.close();
  }

  skipVerification() {
    this.modalComponent.close();
    this.onSkipVerification.emit();
  }

  onEditInformation() {
    this.verificationStep = VerificationStep.VerificationEdit;
    this.initBusinessIdentityForm(
      this.businessIdentities.find((e) => e.id === this.businessIdentityForm.value.currentBusinessId)
    );
  }

  onSubmitWithoutChanges() {
    this.onboardingService
      .verifyBusinessNoChanges({
        currentBusinessId: this.businessIdentityForm.value.currentBusinessId,
      })
      .pipe(
        tap((response: any) => {
          if (response.verified) {
            this.notificationService.notification('success', 'Business account verified successfully');
            this.usersService.getCurrentProfile().subscribe();
          } else {
            this.notificationService.notification(
              'error',
              'We are not able to verify your account. Please check the details and try again'
            );
          }
          this.handleVerificationResult(response.verified);
        })
      )
      .subscribe({
        error: () => {
          this.notificationService.notification(
            'error',
            'We are not able to verify your account. Please check the details and try again'
          );
          this.verificationStep = VerificationStep.VerificationResult;
        },
      });
  }

  onSubmitIdentityForm() {
    if (this.isFormUnChanged()) {
      this.onSubmitWithoutChanges();
      return;
    }

    const { businessName, address, city, postalCode, region, taxId, currentBusinessId } =
      this.businessIdentityForm.value;
    this.onboardingService
      .verifyBusiness({
        currentBusinessId,
        businessAddress: {
          address,
          city,
          postalCode,
          region,
        },
        businessName,
        taxId,
        autofilled: this.isAutofilled,
      })
      .pipe(
        tap((response: any) => {
          if (response.verified) {
            this.notificationService.notification(
              'success',
              this.isAutofilled ? 'Business account verified successfully' : 'Application submitted successfully'
            );
            this.usersService.getCurrentProfile().subscribe();
          } else {
            this.notificationService.notification(
              'error',
              'We are not able to verify your account. Please check the details and try again'
            );
          }
          this.handleVerificationResult(response.verified);
        })
      )
      .subscribe({
        error: () => {
          this.notificationService.notification(
            'error',
            'We are not able to verify your account. Please check the details and try again'
          );
        },
      });
  }

  handleVerificationResult(success: boolean) {
    if (this.isAutofilled && success) {
      this.closeModal();
      return;
    }
    this.isVerificationSucceeded = success;
    this.verificationStep = VerificationStep.VerificationResult;
  }

  setInitialVerificationModalState(proveResult: ProveBusinessVerificationModel) {
    if (!proveResult) {
      this.verificationStep = VerificationStep.VerificationStart;
      this.onboardingService.setVerifyBusinessModalVisibility(true);
      return;
    }

    const isVerificationFailed =
      typeof proveResult.businessVerifyResponse?.status === 'number' &&
      (proveResult.businessVerifyResponse?.status !== 0 || proveResult.businessVerifyResponse?.verified === false);
    const isVerificationSuccess =
      typeof proveResult.businessVerifyResponse?.status === 'number' &&
      (proveResult.businessVerifyResponse?.status === 0 || proveResult.businessVerifyResponse?.verified === true);
    const isBusinessIdentityPrefilled = proveResult.isBusinessPrefilled;
    this.businessIdentities = proveResult.businessIdentities ?? [];
    if (isVerificationFailed) {
      this.isVerificationSucceeded = false;
      this.verificationStep = VerificationStep.VerificationResult;
    } else if (isVerificationSuccess) {
      this.isVerificationSucceeded = true;
      this.verificationStep = VerificationStep.VerificationResult;
    } else if (isBusinessIdentityPrefilled) {
      if (this.hasBusinessIdentities) {
        this.verificationStep = VerificationStep.VerificationReview;
        this.initIdentityForm(proveResult);
        this.isAutofilled = true;
      } else {
        this.verificationStep = VerificationStep.VerificationEdit;
        this.showManualEntryError = true;
        this.isManualEntry = true;
      }
    } else {
      this.verificationStep = VerificationStep.VerificationStart;
    }
    this.onboardingService.setVerifyBusinessModalVisibility(true);
  }

  onBack() {
    if (this.verificationStep === VerificationStep.VerificationEdit) {
      this.verificationStep = VerificationStep.VerificationReview;
    }
  }

  initIdentityForm(proveResult: ProveBusinessVerificationModel) {
    this.businessIdentities = proveResult.businessIdentities;
    if (this.hasBusinessIdentities) {
      this.initBusinessIdentityForm(this.businessIdentities[0]);
    }
  }

  initBusinessIdentityForm(identity: BusinessIdentity) {
    const { address, city, postalCode, region } = identity.businessAddress;
    this.initialBusinessIdentityForm = {
      businessName: identity.businessName,
      address,
      city,
      postalCode,
      region: region,
      taxId: identity.taxId,
    };
    this.businessIdentityForm.patchValue(
      {
        businessName: identity.businessName,
        address,
        city,
        postalCode,
        region,
        taxId: identity.taxId,
        currentBusinessId: identity.id,
      },
      {
        emitEvent: false,
      }
    );
  }

  get hasBusinessIdentities() {
    return this.businessIdentities && this.businessIdentities.length > 0;
  }

  isFormUnChanged() {
    if (!this.initialBusinessIdentityForm) {
      return false;
    }
    return isEqual(this.initialBusinessIdentityForm, omit(this.businessIdentityForm.value, 'currentBusinessId'));
  }

  get selectedBusiness() {
    return this.businessIdentities?.find((b) => b.id === this.businessIdentityForm.value.currentBusinessId);
  }
}
