<app-modal #modal [modalConfig]="modalConfig">
  <div class="max-width">
    <div class="title">How DealNow works for off-site listings:</div>
    <div class="mt-3 sub-text">
      DealNow is a quick way to do a private-party car transaction. It’s ideal for when you want a safe and convenient
      way to close the deal.
    </div>
    <div class="d-flex align-items-center mt-4 item-heading">
      <img src="assets/fireIcon.svg" alt="fireIcon" class="me-3 icon-size" />
      <div class="heading-width">Use DealNow to send an offer to a seller anywhere.</div>
    </div>
    <div class="d-flex align-items-center mt-4 item-heading">
      <img src="assets/externalLinkIcon.svg" alt="fireIcon" class="me-3 icon-size" />
      <div class="heading-width">Message the seller the DealNow invite on the external listing site</div>
    </div>

    <div class="d-flex align-items-center mt-4 item-heading">
      <img src="assets/dollar-circle-green.svg" alt="fireIcon" class="me-3 icon-size" />
      <div class="heading-width">Instant transfer of money P2P with no transaction fees</div>
    </div>
    <div class="d-flex align-items-center mt-4 item-heading">
      <img src="assets/smallPALogo.svg" alt="fireIcon" class="me-3 icon-size" />
      <div class="heading-width">
        $150 closing fee paid by buyer, seller, or split. <span class="fw-bold">Only pay if the deal goes through</span>
      </div>
    </div>
  </div>

  <button class="btn-primary w-100 mt-4" (click)="onGetStarted()">Get started</button>
</app-modal>
