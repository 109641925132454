import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { ModalAddVehicleBuyerForm } from '../modal-add-vehicle-buyer/modal-add-vehicle-buyer.component';
import { Observable } from 'rxjs';
import { ListingCategoryTypes } from 'src/app/models';

@Component({
  selector: 'app-modal-select-vehicle-type',
  templateUrl: './modal-select-vehicle-type.component.html',
  styleUrl: './modal-select-vehicle-type.component.scss',
})
export class ModalSelectVehicleTypeComponent {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();
  @Output() vehicleType = new EventEmitter<string>();
  @Input() onNext$: (form: ModalAddVehicleBuyerForm) => Observable<any> = null;
  @Output() goBackEvent = new EventEmitter<string>();

  modalConfig: ModalConfig = {};
  vehicleTypes = [
    {
      value: ListingCategoryTypes.Auto,
      img: '/assets/car-icons/vehicle-car-gray.svg',
      imgBlue: '/assets/car-icons/vehicle-car-blue.svg',
      text: 'Auto',
    },
    {
      value: ListingCategoryTypes.ATVOHV,
      img: '/assets/car-icons/vehicle-atv-gray.svg',
      imgBlue: '/assets/car-icons/vehicle-atv-blue.svg',
      text: 'ATV',
    },
    {
      value: ListingCategoryTypes.Motorcycle,
      img: '/assets/car-icons/vehicle-motorcycle-gray.svg',
      imgBlue: '/assets/car-icons/vehicle-motorcycle-blue.svg',
      text: 'Motorcycle',
    },
    {
      value: ListingCategoryTypes.Boat,
      img: '/assets/car-icons/vehicle-boat-gray.svg',
      imgBlue: '/assets/car-icons/vehicle-boat-blue.svg',
      text: 'Boat',
    },
    {
      value: ListingCategoryTypes.RV,
      img: '/assets/car-icons/vehicle-rv-gray.svg',
      imgBlue: '/assets/car-icons/vehicle-rv-blue.svg',
      text: 'RV',
    },
    {
      value: ListingCategoryTypes.Trailer,
      img: '/assets/car-icons/vehicle-trailer-gray.svg',
      imgBlue: '/assets/car-icons/vehicle-trailer-blue.svg',
      text: 'Trailer',
    },
    {
      value: ListingCategoryTypes.Auto,
      img: '/assets/car-icons/vehicle-truck-gray.svg',
      imgBlue: '/assets/car-icons/vehicle-truck-blue.svg',
      text: 'Truck',
    },
  ];

  hovered: any = null;
  selectedVehicleType: ListingCategoryTypes;

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Select vehicle type',
      modalDialogClass: 'extend-width',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
      backOption: true,
      hideLogo: true,
      onBackFunction: () => {
        this.goBackEvent.emit('vehicle-type');
        this.modalComponent.close();
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  onVehicleTypeSelect(vehicleType) {
    this.selectedVehicleType = vehicleType.text;
    this.vehicleType.emit(vehicleType.value);
    this.modalComponent.close();
  }
}
