<div class="sub-header my-3">Payment method</div>
@if (form) {
<form [formGroup]="form" class="mt-1">
  <div class="form-group mb-3">
    <label class="form-label" for="">Name on card</label>
    <input
      type="text"
      class="form-control"
      formControlName="name"
      [ngClass]="{ 'is-invalid': f.name.errors && f.name.dirty}"
    />
    @if (f.name.errors && f.name.dirty) {
    <div class="invalid-feedback">
      @if (f.name.errors.required) {
      <div>Name is required</div>
      }
    </div>
    }
  </div>
  <div class="form-group mb-3">
    <label class="form-label" for="cardNumber">Card number</label>
    <div id="cardNumber" class="form-control" [ngClass]="{ 'is-invalid': errors.cardNumber}"></div>
    @if (errors.cardNumber) {
    <div class="invalid-feedback d-block">{{errors.cardNumber}}</div>
    }
  </div>
  <div class="form-group mb-3 d-flex" style="gap: 5px">
    <div class="w-100">
      <label class="form-label" for="cardExpiry">Exp date</label>
      <div id="cardExpiry" class="form-control" [ngClass]="{ 'is-invalid': errors.cardExpiry}"></div>
      @if (errors.cardExpiry) {
      <div class="invalid-feedback d-block">{{errors.cardExpiry}}</div>
      }
    </div>
    <div class="w-100">
      <label class="form-label" for="cardCvc">CVC</label>
      <div id="cardCvc" class="form-control" [ngClass]="{ 'is-invalid': errors.cardCvc}"></div>
      @if (errors.cardCvc) {
      <div class="invalid-feedback d-block">{{errors.cardCvc}}</div>
      }
    </div>
  </div>
  <div class="form-group mb-3">
    <label class="form-label" for="postal">Zip code</label>
    <input
      id="postal"
      type="text"
      class="form-control"
      formControlName="postalCode"
      [ngClass]="{ 'is-invalid': f.postalCode.errors && f.postalCode.dirty }"
    />
    @if (f.postalCode.errors && f.postalCode.dirty) {
    <div class="invalid-feedback">
      @if (f.postalCode.errors.required) {
      <div>Zip code is required</div>
      }
    </div>
    }
  </div>
</form>
}

<div class="disclaimer mt-2">
  By clicking pay now you authorize PrivateAuto to charge your card and process the fee.
</div>

@if (!loading) {
<button
  [disabled]="!form.dirty || form.invalid || stripeElementErrors"
  class="btn-primary w-100 mt-4"
  (click)="onSubmitClick()"
>
  {{btnTxt}}
</button>
} @if (loading) {
<div class="w-100 text-center mt-3">
  <div class="spinner-border me-2 text-center" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
}
