<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">Are you the legal owner of the {{vehicleAddedListing?.name}}?</div>
  <div>
    For the bill of sale and title purposes the legal owner of the vehicle would need to have their own verified account
    to sell this vehicle.
  </div>
  <div class="body mt-3">
    <div class="options-items-container d-flex align-items-center justify-content-center">
      @for (option of options; track option; let i = $index) {
      <div
        class="option-item d-flex align-items-center justify-content-center"
        [ngClass]="{ 'ms-3' : i > 0, 'selected': option.value === selectedOption }"
        (click)="onOptionClick(option)"
      >
        <div class="option-item-name text-center fw-bold">{{option.name | titlecase}}</div>
      </div>
      }
    </div>

    <button class="btn btn-primary w-100 mt-3 p-2 fw-bold" [disabled]="selectedOption === null" (click)="onNextClick()">
      Next
    </button>
  </div>
</app-modal>
