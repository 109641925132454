import { ProveBusinessVerificationModel, ProveVerificationModel } from './prove-verification.model';
import { IOrganization } from './organization.model';
export enum UserStatus {
  active = 'active',
  banned = 'banned',
  deleted = 'deleted',
}

export interface IUserConnectedBankAccounts {
  _id: string;
  access_token: string;
  account_id: string;
  institution: string;
}

export interface IUserCognito {
  accessToken: string;
  idToken: string;
  exp: number;
  attributes?: any;
}

export interface IUserSignature {
  type: string;
  image: string;
}

export enum VerificationProvider {
  Plaid = 'plaid',
  Prove = 'prove',
}

export interface IUser {
  _id: string;
  __v: number;
  cognito: IUserCognito;
  organizationId?: string;
  userDetails: {
    cognitoUsername: string;
    role: string;
    firstName: string;
    lastName: string;
    // Shortened fullname - Firstname L.
    shortName?: string;
    nickname: string;
    email: string;
    dateOfBirth: Date;
    socialSecurityNumber: string;
    phoneNumber: string;
    address1: string;
    address2?: string;
    banUser: boolean;
    profileImage: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    agreementNumber: number;
    personNumber: number;
    connectedAccounts: IUserConnectedBankAccounts[];
    loan: {
      id?: number;
    };

    driverLicenseStatus: boolean;
    twoFactorVerifyCode: {
      code: number;
      status: boolean;
    };
    usa_kyc?: {
      status: string;
      evaluation_token: string;
      entity_token: string;
      documents_needed: any;
    };
    stripeUserId: string;
  };
  signature: IUserSignature;
  verification: {
    vouched: string;
    phone: boolean;
    email: boolean;
    bank: boolean;
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
    isLicenseVerified: boolean;
    isBankConnected: boolean;
    isVerified?: boolean;
    awaitingPlaidResult?: boolean;
    isUserPartiallyVerified?: boolean;
    identityProvider?: VerificationProvider;
    // use only on prove verified users
    livenessVerified?: boolean;
  };
  bankInfo?: {
    status?: string;
    bankInactive: Date;
    bankClosed: Date;
    isClosedAutomatically: boolean;
  };
  funds: {
    deposit: { date: string; transcationId: string; transcationAmount: string }[];
    withDraw: { date: string; transcationId: string; transcationAmount: string }[];
  };
  notifications: {
    transactions: {
      email: boolean;
      sms: boolean;
      iosPush: boolean;
      androidPush: boolean;
    };
    marketing: {
      email: boolean;
      sms: boolean;
    };
    lastPrompt: Date;
  };
  oauth: {
    sub: string;
    email_verified: string;
    email: string;
  }[];
  createdAt: Date;
  privateAutoPay: {
    depositDate: Date;
    accountNumber: number;
    fundsVerified: boolean;
  };
  status: UserStatus;

  /**
   * Other flags, used to display certain UI elements
   */
  buyerPageVisitsCount: number;
  loginCount: number;
  chatCreationAllowed?: boolean;
  onBoarding: {
    moneyPage: {
      addFunds?: boolean;
      withdrawFunds?: boolean;
    };
  };

  proveResult?: ProveVerificationModel;

  proveBusinessResult?: ProveBusinessVerificationModel;

  organization?: IOrganization;
  isOrganizationOwner?: boolean;
  isOrganizationCreator?: boolean;
}

export class User implements IUser {
  _id: string;
  __v: number;
  cognito: IUserCognito;
  userDetails: {
    cognitoUsername: string;
    role: string;
    name: string;
    firstName: string;
    lastName: string;
    nickname: string;
    email: string;
    dateOfBirth: Date;
    socialSecurityNumber: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    banUser: boolean;
    profileImage: string;
    campaignId: number;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    agreementNumber: number;
    personNumber: number;
    connectedAccounts: IUserConnectedBankAccounts[];
    driverLicenseStatus: boolean;
    twoFactorVerifyCode: {
      code: number;
      status: boolean;
    };
    loan: {
      id?: number;
    };
    usa_kyc?: {
      status: string;
      evaluation_token: string;
      entity_token: string;
      documents_needed: any;
    };
    stripeUserId: string;
  };

  signature: IUserSignature;
  privateAutoPay: {
    depositDate: Date;
    accountNumber: number;
    fundsVerified: boolean;
  };

  verification: {
    vouched: string;
    phone: boolean;
    email: boolean;
    bank: boolean;
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
    isLicenseVerified: boolean;
    isBankConnected: boolean;
    isVerified?: boolean;
  };

  notifications: {
    transactions: {
      email: boolean;
      sms: boolean;
      iosPush: boolean;
      androidPush: boolean;
    };
    marketing: {
      email: boolean;
      sms: boolean;
    };
    lastPrompt: Date;
  };

  funds: {
    deposit: [
      {
        date: string;
        transcationId: string;
        transcationAmount: string;
      }
    ];
    withDraw: [
      {
        date: string;
        transcationId: string;
        transcationAmount: string;
      }
    ];
  };

  type: string;
  createdAt: Date;
  oauth: {
    sub: string;
    email_verified: string;
    email: string;
  }[];

  status: UserStatus;
  buyerPageVisitsCount: number;
  loginCount: number;

  onBoarding: {
    moneyPage: {
      addFunds: boolean;
      withdrawFunds: boolean;
    };
  };

  initialReferrer?: string;

  constructor(private readonly data: IUser) {
    Object.assign(this, data);
  }

  get name() {
    if (!this.userDetails) {
      return '';
    }
    if (this.userDetails.name) {
      return this.userDetails.name;
    }

    return `${this.userDetails.firstName} ${this.userDetails.lastName}`;
  }

  get shortName() {
    if (!this.userDetails) {
      return '';
    }
    if (this.userDetails.firstName && this.userDetails.lastName) {
      return `${this.userDetails.firstName} ${this.userDetails.lastName[0]}`;
    }

    return this.name;
  }

  get profileImage() {
    return this.userDetails?.profileImage ? this.userDetails.profileImage : null;
  }
}

export const defaultUserValues = {
  firstName: 'Leslie',
  lastName: 'Knope',
  nickname: 'Leslie K.',
  phoneNumber: '+14352293376',
  address1: '123 Main St.',
  city: 'Pawnee',
  state: 'IN',
  zipcode: '46001',
};
