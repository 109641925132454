import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; // Don't validate empty values to allow required validator to handle them
    }

    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // Protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IPv4 address
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Port and path
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
        '(\\#[-a-zA-Z\\d_]*)?$' // Fragment locator
    );

    const isValid = urlPattern.test(control.value);
    return isValid ? null : { invalidUrl: true };
  };
}
