<ul class="navCard ps-0">
  @for (nav of links; track nav; let i = $index) {
  <li class="navItem">
    <a [routerLink]="nav.link" class="w-100">
      <div class="d-flex justify-content-between">
        <span class="label">{{nav.label}}</span>
        <button class="btn-icon">
          <img alt="" src="/assets/chevron-right.svg" />
        </button>
      </div>
    </a>
  </li>
  }
</ul>
