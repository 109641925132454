<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">Which vehicle are you wanting to buy?</div>

  <div class="row">
    <div *ngFor="let vehicleType of vehicleTypes" class="col-4 d-flex flex-column">
      <div
        class="d-flex flex-column align-items-center justify-content-center vehicle-type-box m-2 p-0"
        (click)="onVehicleTypeSelect(vehicleType)"
        (mouseover)="hovered = vehicleType.text"
        (mouseleave)="hovered = null"
      >
        <img
          [src]="hovered === vehicleType.text || selectedVehicleType === vehicleType.text ? vehicleType.imgBlue : vehicleType.img"
          alt="VehicleType image"
          class="img-fluid"
        />
        <div class="text-muted pt-1">{{ vehicleType.text }}</div>
      </div>
    </div>
  </div>
</app-modal>
