<ng-template #content>
  <div class="popover-title-container d-flex justify-content-between">
    <div class="">
      <ng-content select="[title]"></ng-content>
    </div>
    <img src="assets/close.svg" alt="popover close" class="close-btn me-2" (click)="onCloseClick()" />
  </div>
  <div class="popover-body-container">
    <ng-content select="[body]"></ng-content>
  </div>
</ng-template>
<div #targetContainer class="invisible-reference-container">
  <button
    disabled
    #target="ngbPopover"
    type="button"
    class="invisible-reference w-100 h-100"
    [ngbPopover]="content"
    (shown)="onShown()"
    (hidden)="onHidden()"
  ></button>
</div>

@if (masked && isOpen) {
<div class="mask"></div>
}
