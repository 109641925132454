<app-modal #modal [modalConfig]="modalConfig">
  <div customHeader class="header d-flex align-items-center justify-content-between p-4">
    <div></div>
    <div class="title">Owner invitation</div>

    <button class="close ms-auto" (click)="closeModal()">
      <img src="assets/close.svg" alt="" />
    </button>
  </div>
  <div class="body">
    <div class="invite-business-owner">
      <h2 class="subtitle left">Invite business owner to join</h2>
      <p>Please invite the owner of (Business name) to create a DealNow account to finish the business setup</p>
      <form [formGroup]="form">
        <div class="form-group">
          <label for="email">Owner email address</label>
          <input
            id="email"
            type="email"
            formControlName="email"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
          />
          @if (submitted && f.email.errors) {
          <div class="invalid-feedback">
            @if (f.email.errors.required) {
            <div>Email is required</div>
            }
          </div>
          }
        </div>
        <button class="btn btn-primary w-100" type="submit" (click)="sendInvite()">Send Invite</button>
      </form>
    </div>
  </div>
</app-modal>
