import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subject, merge, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ISellerListing, ListingCategoryTypes } from 'src/app/models';
import {
  DOCUMENT_FILE_SIZE,
  MultipleFileUploadResult,
  NotificationService,
  SellerListingService,
  SellerService,
  UploadService,
} from 'src/app/core/services';
import { convertDecodedVinInfoToListing } from 'src/app/shared/utils-listing';
import { Router } from '@angular/router';
import { PresetFlowConfig } from 'src/app/deal-now-deal-create/deal-now-deal-create.model';
import { ModalConfig } from '../modal/modal.model';
import { ModalComponent } from '../modal/modal.component';
import { DuplicateDeal, ModalDuplicateDealComponent } from '../modal-duplicate-deal/modal-duplicate-deal.component';
import { OptionsSlideItem } from '../options-slide/options-slide.component';
import STATES, { IState } from 'src/app/statics/states-hash';

export type ShoulderedBy = 'buyer' | 'seller';

enum VehicleTypeTemplate {
  autoTemplate = 'autoTemplate',
  boatTemplate = 'boatTemplate',
  rvTemplate = 'rvTemplate',
}

const getYearList = () => {
  const currentYear = new Date().getFullYear() + 1;
  const startYear = 1900;
  const years = [];
  for (let i = currentYear; i >= startYear; i--) {
    years.push(`${i}`);
  }
  return years;
};

export const ALLOWED_REGISTERED_YEARS = getYearList();

export const createYearValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const result = ALLOWED_REGISTERED_YEARS.includes(value) || !value ? null : { invalid: true };
    return result;
  };
};

export interface ModalAddVehicleBuyerForm {
  year: number;
  make: string;
  model: string;
  price: number;
  vehicleType: string;
  registrationNumber?: string;
  shoulderedBy: ShoulderedBy;
  listingId?: string;
  vin?: string;
  mainImg?: string;
  personalInspection?: boolean;
  mechanicInspection?: boolean;
  testDrive?: boolean;
}

export interface ModalAddVehicleBuyerExternalListing
  extends Pick<ISellerListing, '_id' | 'RegistrationYear' | 'CarMake' | 'CarModel'> {
  img: string;
  vin: string;
  amount?: number;
  paidBy?: ShoulderedBy;
}

type VehicleTypes =
  | ListingCategoryTypes.Auto
  | ListingCategoryTypes.ATVOHV
  | ListingCategoryTypes.Motorcycle
  | ListingCategoryTypes.Boat
  | ListingCategoryTypes.RV;

const MANUAL_OPTION: OptionsSlideItem = { text: 'Make/Model', value: 'manualEntry' };
const VIN_OPTION: OptionsSlideItem = { text: 'VIN', value: 'vin' };
@Component({
  selector: 'app-modal-add-vehicle-buyer',
  templateUrl: './modal-add-vehicle-buyer.component.html',
  styleUrls: ['./modal-add-vehicle-buyer.component.scss'],
})
export class ModalAddVehicleBuyerComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @ViewChild('autoTemplate', { static: true }) autoTemplate!: TemplateRef<any>;
  @ViewChild('boatTemplate', { static: true }) boatTemplate!: TemplateRef<any>;
  @ViewChild('rvTemplate', { static: true }) rvTemplate!: TemplateRef<any>;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() onClose = new EventEmitter();
  @Output() updateDeals = new EventEmitter();
  @Output() onCreateNewDealFromListing = new EventEmitter<string>();
  @Input() vehicleType: ListingCategoryTypes;
  @Input() onNext$: (form: ModalAddVehicleBuyerForm) => Observable<any> = null;
  @Input() externalListing: ModalAddVehicleBuyerExternalListing;
  @Input() presetFlow: PresetFlowConfig;
  @Output() goBackEvent = new EventEmitter<string>();

  @ViewChild('duplicateDealModal') private readonly duplicateDealModalComponent: ModalDuplicateDealComponent;

  selectedVehicleTypeTemplate: TemplateRef<any>;
  showDuplicateDealModal = false;
  showGetReportBanner = false;
  modalConfig: ModalConfig = {};
  form: UntypedFormGroup;
  vehicleTypeTemplates = VehicleTypeTemplate;
  vehicleInfoSection = true;
  offerSection = false;
  errorOnVinFilledOut = false;
  states = STATES;
  listingCategoryTypes = ListingCategoryTypes;
  images = [];

  vehicleTypes: VehicleTypes[] = [
    ListingCategoryTypes.Auto,
    ListingCategoryTypes.Boat,
    ListingCategoryTypes.ATVOHV,
    ListingCategoryTypes.Motorcycle,
    ListingCategoryTypes.RV,
  ];

  inputOptions: OptionsSlideItem[] = [VIN_OPTION, MANUAL_OPTION];
  selectedOption = VIN_OPTION;
  formInvalid = false;
  photoUploaded: any;
  dealNowId: string;

  // Typeahead year field
  @ViewChild('year', { static: true }) year: NgbTypeahead;
  years: string[] = ALLOWED_REGISTERED_YEARS;
  focusYear$ = new Subject<string>();
  clickYear$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickYear$.pipe(filter(() => !this.year.isPopupOpen()));
    const inputFocus$ = this.focusYear$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((input) => {
        const result = input === '' ? this.years : this.years.filter((v) => v.indexOf(input) > -1);
        return result;
      })
    );
  };

  get f() {
    return this.form.controls;
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly sellerService: SellerService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly uploadService: UploadService,
    private readonly sellerListingService: SellerListingService
  ) {
    this.form = fb.group({
      vehicleType: [this.listingCategoryTypes.Auto],
      year: [null, [Validators.required, createYearValidator()]],
      make: [null, [Validators.required]],
      model: [null, [Validators.required]],
      price: [null, [Validators.required]],
      listingId: [null],
      mainImg: [null],
      state: ['Select State'],
      vin: [null],
      personalInspection: [false],
      mechanicInspection: [false],
      testDrive: [false],
    });
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Add vehicle by buyer modal',

      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit();
        return true;
      },
      backOption: true,
      hideLogo: true,
      onBackFunction: () => {
        if (this.offerSection) {
          this.vehicleInfoSection = true;
          this.offerSection = false;
        } else {
          this.goBackEvent.emit('vehicle-info');
          this.modalComponent.close();
        }
      },
    };
    this.selectedVehicleTypeTemplate = this.autoTemplate;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, externalListing } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent?.open();
      this.initDetails();
    }

    if (externalListing?.currentValue) {
      this.fillFormWithExternalListing(externalListing.currentValue);
    }
  }

  initDetails() {
    this.showGetReportBanner = true;
    if (this.vehicleType) {
      this.form.patchValue({ vehicleType: this.vehicleType });
    }
  }

  changeVehicleType(vehicleType: VehicleTypes) {
    this.form.patchValue({ vehicleType });
    this.form.markAsDirty();
    if (vehicleType === this.listingCategoryTypes.RV) {
      this.selectedOption = MANUAL_OPTION;
    }
    this.onTemplateChange(vehicleType);
  }

  fillFormWithExternalListing(listing: ModalAddVehicleBuyerExternalListing) {
    const { CarMake, CarModel, RegistrationYear, _id, amount: price } = listing;
    this.form.patchValue({
      year: RegistrationYear.toString(),
      make: CarMake,
      model: CarModel,
      listingId: _id,
      price,
    });
    this.offerSection = true;
    this.vehicleInfoSection = false;
  }

  onNextClick() {
    if (!this.offerSection) {
      if (this.selectedOption.value === 'vin' && this.f.vehicleType.value !== this.listingCategoryTypes.Boat) {
        this.decodeVin();
        return;
      }

      if (this.form.controls.make.invalid || this.form.controls.model.invalid || this.form.controls.year.invalid) {
        this.formInvalid = true;
        return;
      }
      this.vehicleInfoSection = false;
      this.formInvalid = false;
      this.offerSection = true;
      return;
    }
    if (this.form.controls.price.invalid) {
      this.formInvalid = true;
      return;
    }

    const { year, make, model, vin } = this.form.getRawValue();
    this.duplicateDealModalComponent.checkForDuplicateDealByListingDetails(year, make, model, vin);
  }

  onDuplicateDealCheckValid() {
    const form = {
      ...this.form.getRawValue(),
    };
    this.onNext$(form).subscribe((data) => {
      this.dealNowId = data?.dealId;
      if (!this.photoUploaded) {
        this.router.navigate([`/deals/${data.dealId}`]);
        this.modalComponent.close();
      } else {
        this.onPhotosUpload(data.listingId);
      }
    });
  }

  onPhotosUpload(listingId: string) {
    if (!this.photoUploaded) {
      this.notificationService.notification('error', 'No file selected.');
      return;
    }

    const dir = `listings/${listingId}/photos/`;
    this.onFilesUpload(this.photoUploaded, dir).subscribe({
      next: ({ files: images }) => {
        const isPrimaryStatus = !this.images?.length;

        const newImages = images.map((img, i) => {
          const { path } = img;
          const primaryStatus = i === 0 ? isPrimaryStatus : false;

          return {
            images: path,
            primaryStatus,
          };
        });
        this.images = [...newImages];
        this.submitMedia(listingId);
      },
      error: (error) => {
        console.error('File upload failed:', error);
        this.notificationService.notification('error', 'File upload failed.');
      },
    });
  }

  submitMedia(listingId: string) {
    const details = {
      _id: listingId,
      uploadImages: this.images,
    };

    this.sellerListingService.createOrUpdateSellerListing(details).subscribe((results) => {
      this.updateDeals.emit();
      this.router.navigate([`/deals/${this.dealNowId}`]);
      this.modalComponent.close();
    });
  }

  onFilesUpload(files: File[], dir: string): Observable<MultipleFileUploadResult> {
    if (!files || !files.length) {
      return throwError('Please select a file.');
    }

    if (files.some((file) => file.size > DOCUMENT_FILE_SIZE)) {
      return throwError('Maximum file size is 20mb only.');
    }
    const formData: FormData = new FormData();
    files.forEach((file: any) => {
      if (file instanceof File) {
        formData.append('uploads', file, file.name);
      } else if (file instanceof Blob) {
        const convertedFile = new File([file], 'blobfile', { type: file.type });
        formData.append('uploads', convertedFile, convertedFile.name);
      }
    });

    return this.uploadService.uploadMultipleFiles(formData, dir);
  }

  closeDuplicateDeal() {
    this.showDuplicateDealModal = false;
  }

  closeModal() {
    this.modalComponent.close();
  }

  createNewDeal(duplicateDeal: DuplicateDeal) {
    this.closeModal();
    this.onCreateNewDealFromListing.emit(duplicateDeal.deal.listing._id);
  }

  async onFileSelect(event): Promise<void> {
    const eventTarget = event.target;
    const { files } = eventTarget;

    const rawFileList = Array.from(files)?.map((file: File) => this.uploadService.validateImage(file));
    const fileList = await Promise.all(rawFileList);

    if (!fileList || fileList.length === 0) {
      return;
    }

    this.photoUploaded = fileList;

    const firstFile = fileList[0];

    const isValid = await this.uploadService.validateImage(firstFile);

    if (isValid) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const base64Image = e.target.result as string;
        this.form.controls.mainImg.setValue(base64Image);
      };
      reader.readAsDataURL(firstFile);
    }
  }

  decodeVin() {
    const { vin, vehicleType } = this.form.value;
    this.sellerService
      .getInfoByVIN(vin, vehicleType)
      .pipe(
        map((res) => convertDecodedVinInfoToListing(res)),
        map((res) => {
          const {
            RegistrationYear,
            CarMake,
            CarModel,
            mpg,
            DriveType,
            Cylinders,
            Doors,
            Transmission,
            Fuel,
            BodyStyle,
            Engine,
          } = res.listing;

          return {
            vehicleType,
            vinNumber: vin,
            RegistrationYear,
            CarMake,
            CarModel,
            mpg,
            DriveType,
            Cylinders,
            Doors,
            Transmission,
            Fuel,
            BodyStyle,
            Engine,
            disableUserId: true,
          };
        }),
        catchError((error) => {
          this.notificationService.notification('error', 'Details could not be found');
          this.errorOnVinFilledOut = true;
          this.selectedOption = MANUAL_OPTION;
          this.formInvalid = true;
          this.form.patchValue({ vin: null });

          return throwError(() => error);
        })
      )
      .subscribe({
        next: (listing) => {
          this.form.patchValue({
            vin,
            year: listing.RegistrationYear,
            make: listing.CarMake,
            model: listing.CarModel,
            vehicleType: listing.vehicleType,
          });
          this.offerSection = true;
          this.vehicleInfoSection = false;
        },
        error: (error) => {
          // You can also handle errors that might occur during subscription here,
          // but the catchError above should handle most cases.
        },
      });
  }

  onServiceReportClick() {
    const listingId = this.externalListing?._id;
    const vin = this.f.vin.value;
    const vehicleType = this.f.vehicleType.value;
    const amount = this.f.price.value;

    this.router.navigate(['services/history-report/reports'], {
      queryParams: {
        action: 'purchaseReport',
        ...(listingId && { listingId }),
        vin,
        vehicleType,
        amount,
      },
    });
    this.modalComponent.close();
  }

  onBoatServiceReportClick() {
    const listingId = this.externalListing?._id;
    const { make, model, year, price, vin } = this.f;
    if (!listingId) {
      const url = `https://boathistoryreport.com/?utm_source=PrivateAuto&utm_medium=dealnow&utm_campaign=affiliate`;

      window.open(url, '_blank');
      return;
    }

    const boat = `${year.value} ${make.value} ${model.value}`;
    const url = `https://boathistoryreport.com/?utm_source=PrivateAuto&utm_medium=listings&utm_campaign=affiliate&hin=${vin}&boat=${encodeURIComponent(
      boat
    )}&price=${price.value}`;

    window.open(url, '_blank');
  }

  onYearSelected(event: NgbTypeaheadSelectItemEvent) {
    const selectedYear = event.item;
    this.form.patchValue({ year: selectedYear });
  }

  onTemplateChange(vehicleType: VehicleTypes) {
    switch (vehicleType) {
      case ListingCategoryTypes.Auto:
        this.selectedVehicleTypeTemplate = this.autoTemplate;
        break;
      case ListingCategoryTypes.Boat:
        this.selectedVehicleTypeTemplate = this.boatTemplate;
        break;
      case ListingCategoryTypes.RV:
        this.selectedVehicleTypeTemplate = this.rvTemplate;
        break;
      default:
        this.selectedVehicleTypeTemplate = this.autoTemplate;
    }
  }

  changeState(state: IState) {
    this.form.patchValue({ state: state.name });
  }
}
