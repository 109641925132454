<div style="display: flex; flex-direction: column; gap: 16px;">
  <div class="subtitle left">Business account for:</div>
  <form [formGroup]="form">
    <div>
      <label htmlFor="business-account">Business account</label>
      <select id="business-account" formControlName="currentBusinessId" class="form-control form-control-select">
        @for (businessIdentity of businessIdentities; track businessIdentity) {
        <option [value]="businessIdentity.id">{{businessIdentity.businessName}}</option>
        }
      </select>
    </div>
  </form>
  <div class="info-read-only">
    <div class="d-flex flex-column">
      <div class="key">Business name</div>
      <div class="value">{{selectedBusiness.businessName}}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Business address</div>
      <div class="value">{{businessFullAddress}}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Filing date</div>
      @if (selectedBusiness.registrationFiling?.filingDate) {
      <div class="value">{{selectedBusiness.registrationFiling.filingDate | date:'MMM d, y'}}</div>
      } @else {
      <div class="value"> - </div>
      }
    </div>
    <div class="d-flex flex-column" style="flex: 1">
      <div class="key">EIN</div>
      <div class="value">{{selectedBusiness.taxId}}</div>
    </div>
    <div class="d-flex flex-column">
      <div class="key">Registered address</div>
      <div class="value">{{registrationFullAddress}}</div>
    </div>
    <div (click)="onEdit.emit()" class="edit-information" role="button">Edit information</div>
  </div>
  <div class="w-full d-flex flex-column align-items-center">
    <button (click)="onVerify()" class="btn btn-primary w-100">Confirm Information</button>
  </div>
</div>