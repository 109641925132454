import { Channel, TextUserMessage } from '@chatkitty/core';
import { ISellerListing } from '../models/seller-listing.model';
import { IUser } from '../models/user.model';

export enum ChannelStatus {
  active = 'active',
  archived = 'archived',
  removed = 'removed',
  completed = 'completed',
}

export declare type ChatMessageBody = {
  msgType: string;
  message: any;
};

export declare type ChatMessage = TextUserMessage & {
  bodyObject: ChatMessageBody;
  reload?: boolean;
  isEvent?: boolean;
};

export enum ChatMessageTypes {
  TEXT = 'TEXT',
  OFFER = 'OFFER',
  OFFER_EVENT = 'OFFER_EVENT',
  TEST_DRIVE = 'TEST_DRIVE',
  TEST_DRIVE_EVENT = 'TEST_DRIVE_EVENT',
  DEAL = 'DEAL',
  DEAL_EVENT = 'DEAL_EVENT',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  INSPECTION = 'INSPECTION',
  DOCUMENT = 'DOCUMENT',
  SHIPPING = 'SHIPPING',
  DEALNOW = 'DEALNOW',
  DEALNOW_OFFER = 'DEALNOW_OFFER',
}

export enum ChatCategory {
  all,
  selling,
  buying,
  alerts,
}

interface IChatUserDetails
  extends Pick<
    IUser['userDetails'],
    'firstName' | 'lastName' | 'nickname' | 'profileImage' | 'city' | 'state' | 'agreementNumber'
  > {
  shortName?: string;
}

export type IChatUser = Pick<IUser, '_id' | 'createdAt' | 'verification' | 'status'> & {
  userDetails: IChatUserDetails;
};

export interface IChatListing
  extends Pick<
    ISellerListing,
    | '_id'
    | 'uploadImages'
    | 'testDriveLocation'
    | 'availableTimings'
    | 'RegistrationYear'
    | 'CarMake'
    | 'CarModel'
    | 'Price'
    | 'Mileage'
    | 'BodyStyle'
    | 'vehicleType'
    | 'userId'
    | 'slug'
    | 'status'
    | 'soldAt'
    | 'dealPreferences'
    | 'displayType'
    | 'additionalDetails'
  > {}

export interface IChatNavItem {
  channelId: number;
  user: IChatUser;
  listing: IChatListing;
  status: ChannelStatus;
}

export interface LastMessage {
  createdTime: string;
  body: string;
  unread: boolean;
}

// This is the final form of the channel
export type ChatPrivateChannel = Channel & {
  properties: {
    name: string;
    lastMessage: LastMessage;

    isOtherUserVerified?: boolean;
    isOtherUserSeller?: boolean;
    user: IChatUser;

    sellerId?: string;
    buyerId?: string;

    listingId?: string;
    listing?: IChatListing;
    listingImage?: string;

    isSelected?: boolean;
    status: ChannelStatus;
    messagesApproved?: boolean;
  };
};

export const isPrivateChannel = (ch): ch is ChatPrivateChannel => ch.type === 'DIRECT' || ch.type === 'PRIVATE';

export type ChatPublicChannel = Channel & {
  properties: {
    name: string;
    lastMessage: LastMessage;
  };
};

export interface SelectedNav {
  channel: ChatPrivateChannel;
  listing: IChatListing;
}

export interface ChatQueryEvents {
  action?: string;
  offerId?: string;
}

export interface DealChangedUrl {
  url: string;
  queryParameters: unknown;
  state: unknown;
}

export interface IReciverNotifications {
  notifySms: boolean;
  notifyEmail: boolean;
  notifyIos: boolean;
  notifyAndroid: boolean;
  phoneNumber: string;
}

export interface IChatBody {
  msgType: ChatMessageTypes;
  message: string;
  chatId?: number;
  notifications?: IReciverNotifications;
}

export type BaseChannelProps = {
  listingId: string;
  buyerId: string;
  sellerId: string;
  status: ChannelStatus;
  messagesApproved?: boolean;
};

// Type definititon for chat kitty typed channels with the properties we have added and messages we use
export type PropDefinedChannel = Channel & {
  properties: BaseChannelProps;
  lastReceivedMessage: TextUserMessage;
};

export const unverifiedMessage =
  'Automatic reply: This seller prefers verified buyers to message them about their vehicle.' +
  ' Get your account verified to continue messaging unless the seller accepts your message.';
