import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { provideEnvironmentNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { WebcamModule } from 'ngx-webcam';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ModalComponent } from './modal/modal.component';
import { ToastComponent } from './toast/toast.component';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { ProfilePhotoComponent } from './profile-photo/profile-photo.component';
import { ModalPhoneVerifyComponent } from './modal-phone-verify/modal-phone-verify.component';
import { ModalEditComponent } from './modal-phone-verify/modal-edit/modal-edit.component';
import { ModalVerifyComponent } from './modal-phone-verify/modal-verify/modal-verify.component';
import { ModalSignatureComponent } from './modal-signature/modal-signature.component';
import { PaginationControlsComponent } from './pagination/pagination.component';
import { ModalReportUserComponent } from './modal-report-user/modal-report-user.component';
import { ModalLeaveChatComponent } from './modal-leave-chat/modal-leave-chat.component';
import { ModalPayAndSetLiveComponent } from './modal-pay-and-set-live/modal-pay-and-set-live.component';
import { ModalSessionTimeoutComponent } from './modal-session-timeout/modal-session-timeout.component';
import { ModalCameraComponent } from './modal-camera/modal-camera.component';
import { PlacesAutocompleteInput } from './places-autocomplete-input/places-autocomplete-input.component';
import { ListingCardComponent } from './listing-card/listing-card.component';
import { PipeModule } from '../pipe/pipe.module';
import { VerificationDeniedModalComponent } from './verification-denied-modal/verification-denied-modal.component';
import { StepVisualComponent } from './step-visual/step-visual.component';
import { ModalLeaveLoanComponent } from './modal-leave-loan/modal-leave-loan.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { NavigationCardComponent } from './navigation-card/navigation-card.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { PaymentMethodSelectorComponent } from '../account/payments/payment-method-selector/payment-method-selector.component';
import { ModalAddPaymentMethod } from '../account/payments/modal-add-payment-method/modal-add-payment-method.component';
import { ModalArchiveChatComponent } from './modal-archive-chat/modal-archive-chat.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { ModalChatMessageComponent } from './modal-chat-message/modal-chat-message.component';
import { ListingImageModule } from './listing-image/listing-image.module';
import { ModalSelectVehicleComponent } from './modal-select-vehicle/modal-select-vehicle.component';
import { ModalVintageVehicleComponent } from './modal-vintage-vehicle/modal-vintage-vehicle.component';
import { VehicleInputStartComponent } from './vehicle-input-start/vehicle-input-start.component';
import { ModalViewPricingComponent } from './modal-view-pricing/modal-view-pricing.component';
import { ModalPayLaterComponent } from './modal-pay-later/modal-pay-later.component';
import { ChipSelectInputComponent } from './chip-select-input/chip-select-input.component';
import { ModalDuplicateDealComponent } from './modal-duplicate-deal/modal-duplicate-deal.component';
import { ModalVerificationInfoComponent } from './modal-verification-info/modal-verification-info.component';
import { ModalSetDisplayNameComponent } from './modal-set-display-name/modal-set-display-name.component';
import { ModalDealNowExplainedComponent } from './modal-deal-now-explained/modal-deal-now-explained.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { ModalPurchaseVehicleReportComponent } from './modal-purchase-vehicle-report/modal-purchase-vehicle-report.component';
import { ModalReportsPurchaseCompleteComponent } from './modal-reports-purchase-complete/modal-reports-purchase-complete.component';
import { ModalVehicleConfirmSellerComponent } from './modal-vehicle-confirm-seller/modal-vehicle-confirm-seller.component';
import { ModalConfirmVehicleDetailsComponent } from './modal-confirm-vehicle-details/modal-confirm-vehicle-details.component';
import { VehicleInputStartDealNowComponent } from './vehicle-input-start-deal-now/vehicle-input-start-deal-now.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { OptionsSlideModule } from './options-slide/options-slide.module';
import { ModalChatLimitComponent } from './modal-chat-limit/modal-chat-limit.component';
import { InlineAlertComponent } from './inline-alert/inline-alert.component';
import { ModalEditInspectionAddressComponent } from './modal-edit-inspection-address/modal-edit-inspection-address.component';
import { ModalSelectVehicleTypeComponent } from './modal-select-vehicle-type/modal-select-vehicle-type.component';
import { ModalShareDealNowComponent } from './modal-share-deal-now/modal-share-deal-now.component';
import { CircularProgressComponent } from './circular-progress/circular-progress.component';

@NgModule({
  declarations: [
    CheckboxComponent,
    ModalComponent,
    ToastComponent,
    UserPanelComponent,
    ProfilePhotoComponent,
    ModalPhoneVerifyComponent,
    ModalEditComponent,
    ModalVerifyComponent,
    ModalSignatureComponent,
    PaginationControlsComponent,
    ModalReportUserComponent,
    ModalLeaveChatComponent,
    ModalPayAndSetLiveComponent,
    ModalPayLaterComponent,
    ModalSessionTimeoutComponent,
    ModalCameraComponent,
    PlacesAutocompleteInput,
    ListingCardComponent,
    VerificationDeniedModalComponent,
    StepVisualComponent,
    ModalLeaveLoanComponent,
    DocumentViewerComponent,
    NavigationCardComponent,
    NavigationHeaderComponent,
    PaymentMethodSelectorComponent,
    ModalAddPaymentMethod,
    ModalArchiveChatComponent,
    ToggleSwitchComponent,
    ModalChatMessageComponent,
    ModalSelectVehicleComponent,
    ModalVintageVehicleComponent,
    VehicleInputStartComponent,
    ModalViewPricingComponent,
    ChipSelectInputComponent,
    ModalDuplicateDealComponent,
    ModalVerificationInfoComponent,
    ModalSetDisplayNameComponent,
    ModalDealNowExplainedComponent,
    ModalPurchaseVehicleReportComponent,
    ModalReportsPurchaseCompleteComponent,
    AddressFormComponent,
    ModalVehicleConfirmSellerComponent,
    ModalConfirmVehicleDetailsComponent,
    VehicleInputStartDealNowComponent,
    ModalChatLimitComponent,
    InlineAlertComponent,
    ModalEditInspectionAddressComponent,
    ModalSelectVehicleTypeComponent,
    ModalShareDealNowComponent,
    CircularProgressComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
    }),
    NgxPaginationModule,
    WebcamModule,
    PipeModule,
    ListingImageModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxExtendedPdfViewerModule,
    OptionsSlideModule,
  ],
  exports: [
    ModalChatMessageComponent,
    CheckboxComponent,
    ModalComponent,
    UserPanelComponent,
    ProfilePhotoComponent,
    ModalPhoneVerifyComponent,
    PaginationControlsComponent,
    ModalReportUserComponent,
    ModalPayAndSetLiveComponent,
    ModalSessionTimeoutComponent,
    ModalCameraComponent,
    PlacesAutocompleteInput,
    ListingCardComponent,
    StepVisualComponent,
    VerificationDeniedModalComponent,
    ModalLeaveLoanComponent,
    ModalSignatureComponent,
    DocumentViewerComponent,
    NavigationCardComponent,
    NavigationHeaderComponent,
    PaymentMethodSelectorComponent,
    ModalAddPaymentMethod,
    ToggleSwitchComponent,
    ModalSelectVehicleComponent,
    ModalVintageVehicleComponent,
    VehicleInputStartComponent,
    ModalViewPricingComponent,
    ModalPayLaterComponent,
    ChipSelectInputComponent,
    ModalDuplicateDealComponent,
    ModalVerificationInfoComponent,
    ModalSetDisplayNameComponent,
    ModalDealNowExplainedComponent,
    ModalPurchaseVehicleReportComponent,
    ModalReportsPurchaseCompleteComponent,
    AddressFormComponent,
    ModalVehicleConfirmSellerComponent,
    ModalConfirmVehicleDetailsComponent,
    VehicleInputStartDealNowComponent,
    ModalChatLimitComponent,
    InlineAlertComponent,
    ModalEditInspectionAddressComponent,
    ModalSelectVehicleTypeComponent,
    ModalShareDealNowComponent,
    CircularProgressComponent,
  ],
  providers: [provideEnvironmentNgxMask()],
})
export class WidgetsModule {}
