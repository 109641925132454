import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService } from 'src/app/core/services';

@AutoUnsubscribe()
@Component({
  selector: 'app-business-verification-result',
  templateUrl: './business-verification-result.component.html',
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class BusinessVerificationResult implements OnInit, OnDestroy {
  @Input() isSuccess: boolean;
  @Output() onContinue = new EventEmitter();
  constructor(private readonly onboardingService: OnboardingService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
