<ng-template #modal class="app-modal">
  @if (!hideHeader) {
  <div
    class="modal-header align-items-end"
    [ngClass]="{ 'pb-0': hideLogo, 'header-rounded': headerRounded,  }"
    [ngStyle]="{ 'background': headerBg }"
  >
    @if (!hideLogo) {
    <img [src]="headerLogo" alt="" class="me-auto" />
    } @if (backOption){
    <div class="d-flex align-items-center" (click)="goBack()">
      <img src="/assets/arrow-left.svg" alt="arrow left" />
      <span class="ms-2">Go back</span>
    </div>
    } @if (!hideClose) {
    <button class="close ms-auto" (click)="close()">
      @if (!hideClose) {
      <img [src]="whiteClose ? 'assets/close-white.svg' : 'assets/close.svg'" alt="" />
      }
    </button>
    }
  </div>
  }
  <ng-content select="[customHeader]"></ng-content>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
</ng-template>
