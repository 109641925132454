import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { OnboardingService } from 'src/app/core/services';
import { ModalComponent } from 'src/app/widgets/modal/modal.component';
import { ModalConfig } from 'src/app/widgets/modal/modal.model';
import { IUser } from 'src/app/models';
import { OrganizationVerificationStatus } from 'src/app/models/organization.model';

const VERIFICATION_STEPS = {
  'individual-verification': {
    id: 'individual-verification',
    title: 'Get yourself verified',
    description: 'Complete individual (KYC) and business (KYB) verification',
    cta: 'Get verifed/setup my account',
    icon: '/assets/verification/welcome/individual-verification.svg',
  },
  'business-verification': {
    id: 'business-verification',
    title: 'Get business verified',
    description: 'Add all business details, including logo, website, and add users',
    cta: 'Finish business profile',
    icon: '/assets/verification/welcome/business-verification.svg',
  },
  'connect-bank': {
    id: 'connect-bank',
    title: 'Connect your bank account',
    description: 'Link your bank account to send and receive funds ',
    cta: 'Link bank account',
    icon: '/assets/verification/welcome/connect-bank.svg',
  },
  'invite-business-owner': {
    id: 'invite-business-owner',
    title: 'Invite the business owner of {{name}}',
    description: 'The business owner will need to verify the business details and finish other setups.',
    cta: 'Invite the business owner',
    icon: '/assets/verification/welcome/connect-bank.svg',
  },
};
const ownerVerificationStepIds = ['individual-verification', 'business-verification', 'connect-bank'];
const regularBusinessAccountVerificationSteps = ['individual-verification', 'invite-business-owner'];
@AutoUnsubscribe()
@Component({
  selector: 'app-verification-welcome-modal',
  templateUrl: './verification-welcome-modal.component.html',
  styleUrls: ['./verification-welcome-modal.component.scss'],
})
export class VerificationWelcomeModal implements OnInit, OnDestroy {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onIndividualVerification = new EventEmitter<IUser>();
  @Output() onBusinessVerification = new EventEmitter<IUser>();
  modalConfig: ModalConfig = {};

  @Input() user: IUser;

  currentStepId = '';

  constructor(private readonly onboardingService: OnboardingService) {}

  verificationSteps = [];

  ngOnInit(): void {
    this.modalConfig = {
      modalDialogClass: 'verification-welcome-modal',
      name: 'Welcome to DealNow Modal',
      size: 'lg',
      beforeClose: async () => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onboardingService.setWelcomeBusinessVerificationModalIsVisible(false);
        return true;
      },
    };
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;

    if (isOpen?.currentValue) {
      this.setVerificationSteps();
      this.findCurrentStepId();
      this.modalComponent?.open();
    }
  }

  setVerificationSteps() {
    if (this.user.isOrganizationOwner) {
      this.verificationSteps = ownerVerificationStepIds.map((e) => VERIFICATION_STEPS[e]);
    } else {
      this.verificationSteps = regularBusinessAccountVerificationSteps.map((e) => ({
        ...VERIFICATION_STEPS[e],
        ...(e === 'invite-business-owner' && {
          title: VERIFICATION_STEPS[e].title.replace('{{name}}', this.user.organization?.name),
        }),
      }));
    }
  }

  findCurrentStepId() {
    this.currentStepId = '';
    this.verificationSteps.forEach((step) => {
      if (this.currentStepId) {
        return;
      }
      if (!this.isStepCompleted(step.id)) {
        this.currentStepId = step.id;
      }
    });
  }

  isStepCompleted(stepId: string) {
    if (!this.user) {
      return false;
    }
    if (stepId === 'individual-verification') {
      return this.user.verification.isLicenseVerified;
    } else if (stepId === 'business-verification') {
      return (
        this.user.organization?.verificationStatus &&
        this.user.organization?.verificationStatus !== OrganizationVerificationStatus.Unverified
      );
    } else if (stepId === 'invite-business-owner') {
      return Boolean(this.user.organization?.ownerId);
    } else {
      return false;
    }
  }

  onClickCta(stepId: string) {
    switch (stepId) {
      case 'individual-verification':
        this.closeModal();
        this.onIndividualVerification.emit(this.user);
        break;
      case 'business-verification':
        this.closeModal();
        this.onBusinessVerification.emit(this.user);
        break;
      case 'invite-business-owner':
        this.closeModal();
        this.onboardingService.setInviteBusinessOwnerModalIsVisible(true);
        break;
      case 'connect-bank':
        break;
      default:
        break;
    }
  }

  closeModal() {
    this.modalComponent.close();
  }
}
