<ng-template #page1>
  <h1 class="fw-bold">Vehicle not found</h1>
  <div class="sub-text pb-2">
    You might have mistyped your plate or VIN. If not, are you listing a Car of Particular Interest (CPI) or vintage
    vehicle?
  </div>

  <div class="option-boxes d-flex d-inline-block">
    @for (option of options; track option) {
    <div class="option-box m-2 cursor-pointer text-center" (click)="option.action()">
      <div class="m-2 text-center">
        <div class="image-container">
          <img alt="option" [src]="option.image" class="m-2 mt-3" />
        </div>
        <div class="box-title pb-2 mt-1">{{option.title}}</div>
        <div class="box-subtext">{{option.subtext}}</div>
      </div>
    </div>
    }
  </div>
</ng-template>

<ng-template #page2>
  <div class="page-2">
    <button (click)="onPage2BackClick()" class="btn-back d-flex align-items-center">
      <img src="assets/arrow-left.svg" alt="back arrow" /><span class="ms-1">Back</span>
    </button>

    <div class="title mt-3">Fill out your vehicle info</div>

    <form [formGroup]="page2Form" class="mt-2">
      <div>
        <div class="label">Year</div>
        <input type="text" class="form-control mt-1" formControlName="RegistrationYear" />
      </div>

      <div class="mt-3">
        <div class="label">Make</div>
        <input type="text" class="form-control mt-1" formControlName="CarMake" />
      </div>

      @if (showModelText || showModelDropdown) {
      <div class="mt-3">
        <div class="label">Model</div>
        @if (showModelText) {
        <input type="text" class="form-control mt-1" formControlName="CarModel" />
        } @if (showModelDropdown) {
        <div ngbDropdown class="btn-dropdown w-100 mt-1">
          <button
            class="form-control d-flex align-items-center justify-content-between text-start"
            id="dropdownBasic2"
            ngbDropdownToggle
          >
            <span class="ellipsis">{{ page2F.CarModel.value }}</span>
            <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
          </button>
          <div ngbDropdownMenu class="dropdown-container" aria-labelledby="dropdownBasic2">
            @for (option of modelOptions; track option) {
            <div ngbDropdownItem class="dropdown-item" (click)="onChangeModelOption(option)">{{option}}</div>
            }
          </div>
        </div>
        }
      </div>
      }

      <div class="mt-3">
        <div class="label">VIN</div>
        <input type="text" class="form-control mt-1" formControlName="vinNumber" />
      </div>
    </form>

    <button
      class="btn-primary w-100 mt-4"
      (click)="onPage2ContinueClick()"
      [disabled]="!page2Form.dirty || page2Form.invalid"
    >
      Continue
    </button>
  </div>
</ng-template>

<app-modal #modal [modalConfig]="modalConfig">
  @if (displayedView === 'page1') {
  <div>
    <ng-template *ngTemplateOutlet="page1;"></ng-template>
  </div>
  } @if (displayedView === 'page2') {
  <div>
    <ng-template *ngTemplateOutlet="page2;"></ng-template>
  </div>
  }
</app-modal>
