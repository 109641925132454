<app-modal #modal [modalConfig]="modalConfig">
  <div customHeader class="header d-flex align-items-center justify-content-between p-4 custom-header">
    <img src="/assets/logo.svg" alt="PrivateAuto logo" />
    <button class="close ms-auto" (click)="closeModal()">
      <img src="assets/close.svg" alt="" />
    </button>
  </div>
  <div class="business-information-body">
    <div class="information-form">
      <form [formGroup]="form" class="flex flex-column">
        <h2 class="subtitle">Business info</h2>
        <div class="form-group">
          <label for="name">Business name</label>
          <input
            id="name"
            type="text"
            formControlName="name"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
          />
          @if (submitted && f.name.errors) {
          <div class="invalid-feedback">
            @if (f.name.errors.required) {
            <div>Business name is required</div>
            }
          </div>
          }
        </div>
        <div class="form-group">
          <label for="website">Website</label>
          <input
            id="website"
            type="text"
            formControlName="website"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.website.errors }"
          />
          @if (submitted && f.website.errors) {
          <div class="invalid-feedback">
            @if (f.website.errors.required) {
            <div>Website URL is required</div>
            }
          </div>
          }
        </div>
        <app-chip-select-input [options]="chipOptions" formControlName="isOwner">
          <span>Do you own 25% or more of this business?</span>
        </app-chip-select-input>
        <div class="w-100" style="padding-top: 12px">
          <button (click)="onSave()" class="btn btn-primary w-100">Save</button>
        </div>
      </form>
    </div>
    <div class="placeholder-section">
      <div style="height: 525px; width: 272px">
        <svg-icon src="/assets/verification/business-information-placeholder.svg"></svg-icon>
      </div>
    </div>
  </div>
  <!-- <div>

  </div> -->
  <!-- <div customHeader class="header d-flex align-items-center justify-content-between p-4">

    <button class="close ms-auto">
      <img src="assets/close.svg" alt="" />
    </button>
  </div> -->
</app-modal>
