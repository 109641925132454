import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CustomDateParserFormatter } from 'src/app/core/services/ngb-datepicker-custom.service';
import { MapLocationModel } from 'src/app/models';
import STATES from 'src/app/statics/states-hash';
import { PlacesAutocompleteInput } from 'src/app/widgets/places-autocomplete-input/places-autocomplete-input.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-edit-business-information',
  templateUrl: './edit-business-information.component.html',
  providers: [],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class EditBusinessInformationComponent implements OnInit, OnDestroy {
  @ViewChild('autocompleteInput') autocompleteInput: PlacesAutocompleteInput;
  @Output() onContinue = new EventEmitter();
  @Input() form: FormGroup;
  @Input() showError: boolean;
  @Input() disablePrimaryFields: boolean;
  isEditing = false;
  submitted = false;
  readonly states = STATES;
  isFullAddressVisible = false;
  location: MapLocationModel;

  ngOnInit(): void {
    this.isFullAddressVisible = this.shouldShowFullAddress();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onVerify() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.onContinue.emit();
  }

  get f() {
    return this.form.controls;
  }

  shouldShowFullAddress() {
    return (
      !this.form.get('address').value &&
      !this.form.get('city').value &&
      !this.form.get('region').value &&
      !this.form.get('postalCode').value
    );
  }

  updateLocation(location: MapLocationModel) {
    this.form.patchValue({
      address: location.address,
      city: location.city,
      region: location.stateShortname,
      postalCode: location.zipcode,
    });
    this.isFullAddressVisible = false;
  }
}
