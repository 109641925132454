import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { provideEnvironmentNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ProveVerificationModal } from './prove-verification-modal/prove-verification-modal.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { StartVerification } from './start-verification/start-verification.component';
import { VerificationLinkSentComponent } from './verification-link-sent/verification-link-sent.component';
import { ReviewInformationComponent } from './review-information/review-information.component';
import { EditInformationComponent } from './edit-information/edit-information.component';
import { DirectiveModule } from '../directive/directive.module';
import { VerificationFailedComponent } from './verification-failed/verification-failed.component';
import { ProveMobileAuthComponent } from './prove-mobile-auth/prove-mobile-auth.component';
import { EditBusinessInformationComponent } from './edit-business-information/edit-business-information.component';
import { VerificationWelcomeModal } from './verification-welcome-modal/verification-welcome-modal.component';
import { BusinessVerificationModal } from './business-verification-modal/business-verification-modal.component';
import { ReviewBusinessInformationComponent } from './review-business-information/review-business-information.component';
import { StartBusinessVerificationComponent } from './start-business-verification/start-business-verification.component';
import { BusinessVerificationResult } from './business-verification-result/business-verification-result.component';
import { BusinessInformationModal } from './business-information-modal/business-information-modal.component';
import { InviteBusinessOwnerModal } from './invite-business-owner-modal/invite-business-owner-modal.component';

@NgModule({
  declarations: [
    ProveVerificationModal,
    StartVerification,
    VerificationLinkSentComponent,
    ReviewInformationComponent,
    EditInformationComponent,
    VerificationFailedComponent,
    ProveMobileAuthComponent,
    EditBusinessInformationComponent,
    VerificationWelcomeModal,
    BusinessVerificationModal,
    StartBusinessVerificationComponent,
    ReviewBusinessInformationComponent,
    BusinessVerificationResult,
    BusinessInformationModal,
    InviteBusinessOwnerModal,
  ],
  exports: [
    ProveVerificationModal,
    VerificationWelcomeModal,
    BusinessVerificationModal,
    BusinessInformationModal,
    InviteBusinessOwnerModal,
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetsModule,
    AngularSvgIconModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    DirectiveModule,
  ],
  providers: [provideEnvironmentNgxMask()],
})
export class ProveVerificationModule {}
