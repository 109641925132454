import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, filter, tap, switchMap } from 'rxjs/operators';
import { ChatKittyService } from 'src/app/core/services/chatKitty.service';
import { UsersService } from 'src/app/core/services/users.service';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import {
  DealClosingFeePayerEnum,
  DealsStatuses,
  IDeal,
  IDealBase,
  IDealBuyer,
  IDealSeller,
  IDealTitleTypes,
} from 'src/app/deals/deals.models';
import { UsallianceLoanStatuses } from './usalliance.service';
import { partyTypes } from 'src/app/deals/summary/deal-parties/deal-parties.component';
import { ISellerListing } from 'src/app/models';
import { IOffer } from 'src/app/marketing-inbox/event-offer/event-offer.model';
import { VerificationService } from './verification.service';

interface StartDealRequest {
  offerId: string;
  chatId: string;
}

interface ConfirmDealRequest {
  dealId: string;
  price: number;
  odometer: number;
  disclosure: string;
  payment: {
    privateAutoPay: { amount: number };
    cash: { amount: number };
    crypto: {
      btc: { amount: number };
    };
  };
}

interface SaveEntityPayload {
  dealId: string;
  type: 'buyer' | 'seller';
  entity: {
    entityName: string;
    isAuthorizedSigner: boolean;
    signerTitle: string;
  };
}

export interface GetDealsListItem {
  _id: string;
  seller: IDealSeller;
  buyer: IDealBuyer;
  status: 'active' | 'pending' | 'purchased' | 'sold' | 'cancelled';
  listing: Pick<
    ISellerListing,
    | '_id'
    | 'vinNumber'
    | 'vehicleType'
    | 'CarMake'
    | 'CarModel'
    | 'RegistrationYear'
    | 'uploadImages'
    | 'Mileage'
    | 'Price'
    | 'slug'
  >;
  cancelledAt: string;
  startedAt: string;
  createdAt: string;
  updatedAt: string;
  latestDealDocument: {
    _id: string;
    deal: string;
    documentTemplate: string;
    __v: number;
    buyerSignedDate: string | null;
    filename: string;
    isBuyerSignatureRequired: boolean;
    isSellerSignatureRequired: boolean;
    key: string;
    name: string;
    sellerSignedDate: string | null;
    url: string;
  };
  offer: Pick<IOffer, '_id' | 'price' | 'chatId'>;
  isBuyerFundsReady: boolean;
  isBuyersFundsSufficient: boolean;
  allDocumentsSigned: boolean;
  services: {
    inspectionOrder?: null | boolean;
    historyReport?: null | boolean;
    shippingOrder?: null | boolean;
    titleCheckOrder?: null | boolean;
    financing?: null | boolean;
  };
}

interface GetDealsListResponse {
  deals: GetDealsListItem[];
}

@Injectable({
  providedIn: 'root',
})
export class DealsService {
  constructor(
    private readonly http: HttpClient,
    private readonly chatKittyService: ChatKittyService,
    private readonly usersService: UsersService,
    private readonly verificationService: VerificationService,

    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) {}

  private readonly dealSummarySubject = new BehaviorSubject<IDeal>(null);
  get dealSummary() {
    return this.dealSummarySubject.asObservable().pipe(filter(Boolean)) as Observable<IDeal>;
  }

  updateDealSummary(updatedDealSummary) {
    const formattedDeal = this.formatDealSummary(updatedDealSummary);
    this.dealSummarySubject.next(formattedDeal);
  }

  getVehicleHistoryReport(listingId) {
    return this.http.get(`${this.config.apiUrl}/vehicles/autocheck/${listingId}`);
  }

  getMySoldDealByListingId(listingId, buyer = '') {
    return this.http.get(`${this.config.apiUrl}/deals/listing/${listingId}`, { params: { buyer } });
  }

  getDealSummary(id: string) {
    return this.http.get(`${this.config.apiUrl}/deals/${id}`).pipe(
      switchMap((deal: IDeal) => {
        const { seller, buyer } = deal;
        return combineLatest([
          this.usersService.configureUser(seller.info),
          this.usersService.configureUser(buyer.info),
        ]).pipe(
          map(([sellerInfo, buyerInfo]) => {
            return {
              ...deal,
              seller: { ...deal.seller, info: sellerInfo },
              buyer: { ...deal.buyer, info: buyerInfo },
            };
          })
        );
      }),
      map((deal: IDeal) => this.formatDealSummary(deal)),
      tap((deal) => {
        this.dealSummarySubject.next(deal);
      })
    );
  }

  formatDealSummary(deal: IDealBase): IDeal {
    const { buyer, seller } = deal;
    buyer.confirmedDate = buyer.confirmedDate && new Date(buyer.confirmedDate);
    seller.confirmedDate = seller.confirmedDate && new Date(seller.confirmedDate);

    const type = this.getTitleType(deal);
    const isReviewed = this.getIsTitleReviewed(deal);
    const title = { type, isReviewed };
    const isPaymentMethodComplete = this.checkIfDealPaymentMethodComplete(deal);
    const isVehicleInfoComplete = this.getIsVehicleInfoComplete(deal);
    const isOfferSectionConfirmed = this.getOfferSectionConfirmed(deal);

    const isDocumentSigningComplete = this.getIsDocumentSigningComplete(deal);
    const isPaymentTransferComplete = this.getIsPaymentTransferComplete(seller);
    const isPartiesSectionComplete = this.getIsPartiesSectionComplete(deal);

    const isAddonServicesStepComplete = this.getIsAddonServicesStepComplete(buyer, seller);
    const isAddonServicesSellerStepComplete = !!seller?.addonServicesConfirmedDate;
    const isAddonServiceBuyerStepComplete = !!buyer?.addonServicesConfirmedDate;
    const isPaymentTransferStarted = this.checkPaymentIfTransferStarted(deal);
    const isDealLoanFinancedAndNotSold =
      [UsallianceLoanStatuses.APPROVED, UsallianceLoanStatuses.FUNDED].includes(deal.loan?.status) &&
      deal.status !== DealsStatuses.Sold;

    const formattedDeal = {
      ...deal,
      isPartiesSectionComplete,
      isVehicleInfoComplete,
      isDocumentSigningComplete,
      isPaymentTransferComplete,
      isAddonServicesSellerStepComplete,
      isAddonServiceBuyerStepComplete,
      isAddonServicesStepComplete,
      isPaymentTransferStarted,
      isOfferSectionConfirmed,
      title,
      isDealLoanFinancedAndNotSold,
      isPaymentMethodComplete,
    };
    return formattedDeal;
  }

  formatDealsListItem(deal: IDealBase) {
    const { _id, buyer, seller, createdAt } = deal;
    const isAddonServicesStepComplete = this.getIsAddonServicesStepComplete(buyer, seller);
    const isDocumentSigningComplete = this.getIsDocumentSigningComplete(deal);
    const isPaymentTransferComplete = this.getIsPaymentTransferComplete(seller);
    const isVehicleInfoComplete = this.getIsVehicleInfoComplete(deal);
    const isPartiesSectionComplete = this.getIsPartiesSectionComplete(deal);

    return {
      _id,
      createdAt,
      isAddonServicesStepComplete,
      isDocumentSigningComplete,
      isPaymentTransferComplete,
      isVehicleInfoComplete,
      isPartiesSectionComplete,
    };
  }

  getIsPartiesSectionComplete(deal: IDealBase): boolean {
    const { buyer, seller, isBuyerFundsReady, isBuyersFundsSufficient } = deal;
    const hasFunds = isBuyerFundsReady || isBuyersFundsSufficient;

    return !!(
      buyer.id &&
      seller.id &&
      buyer.info?.verification.isVerified &&
      seller.info?.verification.isVerified &&
      hasFunds
    );
  }

  getTitleType(deal: IDealBase): IDealTitleTypes {
    return deal.loan ? IDealTitleTypes.Attachment : IDealTitleTypes.Acknowledgement;
  }

  getIsTitleReviewed(deal: IDealBase): boolean {
    const { seller, buyer, loan } = deal;
    const isTitleAcknowledged = !!(seller.titleAcknowledgedDate && buyer.titleAcknowledgedDate);
    const { front, back, completedDate } = deal.listing?.titleAttachment || {};
    const isTitleAttached = !!(front && back && completedDate && loan);
    const type = this.getTitleType(deal);

    return type === IDealTitleTypes.Attachment ? isTitleAttached : isTitleAcknowledged;
  }

  getIsAddonServicesStepComplete(buyer: IDealBuyer, seller: IDealSeller): boolean {
    return !!(buyer?.addonServicesConfirmedDate && seller?.addonServicesConfirmedDate);
  }

  getIsDocumentSigningComplete(deal: IDealBase): boolean {
    const isVehicleInfoComplete = this.getIsVehicleInfoComplete(deal);
    const isReviewed = this.getIsTitleReviewed(deal);
    return isVehicleInfoComplete && !!(deal.allDocumentsSigned && isReviewed);
  }

  getIsPaymentTransferComplete(seller: IDealSeller): boolean {
    return !!seller.confirmPaymentDate;
  }

  getIsVehicleInfoComplete(deal: IDealBase): boolean {
    const { buyer, seller } = deal;
    const isPaymentMethodComplete = this.checkIfDealPaymentMethodComplete(deal);
    return !!(buyer.confirmedDate && seller.confirmedDate) && isPaymentMethodComplete;
  }

  getOfferSectionConfirmed(deal: IDealBase): boolean {
    const { buyer, seller } = deal;
    return !!(buyer.confirmedDate && seller.confirmedDate);
  }

  checkIfDealPaymentMethodComplete(deal: IDealBase): boolean {
    if (!deal.closingFeePayer) {
      return false;
    }
    if (deal.closingFeePayer === DealClosingFeePayerEnum.Split) {
      return Boolean(deal.buyerPaymentMethod && deal.sellerPaymentMethod);
    }
    if (deal.closingFeePayer === DealClosingFeePayerEnum.Buyer) {
      return Boolean(deal.buyerPaymentMethod);
    }
    return Boolean(deal.sellerPaymentMethod);
  }

  checkPaymentIfTransferStarted(deal: IDealBase): boolean {
    const {
      privateAutoPay,
      cash,
      loan,
      crypto: { btc },
    } = deal.payment;

    const availableAllocations = [];

    if (privateAutoPay.amount && privateAutoPay.transferDate) {
      availableAllocations.push(privateAutoPay.transferDate);
    }

    if (cash.amount && cash.transferDate) {
      availableAllocations.push(cash.transferDate);
    }

    if (btc.amount && btc.transferDate) {
      availableAllocations.push(btc.transferDate);
    }

    if (loan?.amount && loan?.transferDate) {
      availableAllocations.push(loan.transferDate);
    }

    return !!availableAllocations.length && availableAllocations.every(Boolean);
  }

  startDeal(data: StartDealRequest) {
    return this.http.post(`${this.config.apiUrl}/deals/start`, data).pipe(
      tap(() => {
        const { offerId: _id, chatId } = data;
        return this.chatKittyService.sendOfferMessage({ _id, chatId }, true).subscribe();
      })
    );
  }

  confirmDeal(data: ConfirmDealRequest) {
    return this.http.post(`${this.config.apiUrl}/deals/confirm`, data);
  }
  undoConfirmation(dealId: string) {
    return this.http.put(`${this.config.apiUrl}/deals/confirm`, { dealId });
  }

  cancelDeal(dealId: string) {
    return this.http.post(`${this.config.apiUrl}/deals/cancel`, { dealId });
  }

  confirmPayment(dealId: string) {
    return this.http.post(`${this.config.apiUrl}/deals/confirm-payment`, { dealId });
  }

  startTransfer(dealId: string) {
    return this.http.post(`${this.config.apiUrl}/deals/start-transfer`, { dealId });
  }

  acknowledgeTitle(dealId: string) {
    return this.http.put(`${this.config.apiUrl}/deals/acknowledge-title`, { dealId });
  }

  confirmAddonServices(dealId: string) {
    return this.http.put(`${this.config.apiUrl}/deals/confirm-addon-services`, { dealId });
  }

  addPendingInvite(dealId: string, partyType: partyTypes) {
    return this.http.post(`${this.config.apiUrl}/deals/${dealId}/invite`, { partyType });
  }

  hasCurrentPendingDeal(listingId: string) {
    return this.http.post(
      `${this.config.apiUrl}/deals/listing/${listingId}`,
      { listingId },
      {
        headers: {
          'X-No-Loader': '1',
        },
      }
    );
  }

  /**
   * Settle balance with the current payment method
   * @param tokenId - payment method
   */
  settleBalance(tokenId) {
    return this.http.post<{ message: string; success: boolean }>(`${this.config.apiUrl}/deals/settle-balance`, {
      tokenId,
    });
  }

  getDuplicateDeal(listing) {
    return this.http.get(`${this.config.apiUrl}/deals/duplicate`, { params: { listing } });
  }

  getDuplicateDealByListingDetails(year, make, model, vin?) {
    return this.http.get(`${this.config.apiUrl}/deals/duplicate`, { params: { year, make, model, vin } });
  }

  /**
   * If the user has an approved loan, and payment allocation for loan is set, use that instead.
   * If the payment allocation for loan is not set, use the approved loan amount.
   * @param deal
   * @returns
   */
  getPrivateAutoPayAmountToSend(deal: IDeal): number {
    const { loan, payment } = deal;
    const paPayAmount = payment.privateAutoPay?.amount;
    const usaLoanAmount = (loan?.amount ? payment.loan?.amount : loan?.amount) || 0;
    return paPayAmount + +usaLoanAmount;
  }

  saveEntity(payload: SaveEntityPayload) {
    const { dealId, entity, type } = payload;
    return this.http.post<{ success: boolean; message: string }>(`${this.config.apiUrl}/deals/${dealId}/entity`, {
      type,
      entity,
    });
  }

  getDealsList() {
    return this.http.get<GetDealsListResponse>(`${this.config.apiUrl}/deals-list`).pipe(
      map(({ deals }) => {
        return deals.map((deal) => {
          const seller = { ...deal.seller, info: this.verificationService.getVerificationDetails(deal.seller.info) };
          const buyer = { ...deal.buyer, info: this.verificationService.getVerificationDetails(deal.buyer.info) };
          return { ...deal, seller, buyer };
        });
      })
    );
  }
}
