<app-modal #modal [modalConfig]="modalConfig">
  <div class="header mt-3">
    <div class="failed-pay-later-header">Deal completed successfully</div>
    <div class="failed-pay-later-red-box d-flex align-items-start py-3 pe-3 ps-2 mt-4">
      <img src="assets/warning-red.svg" alt="" class="icon" />

      <div class="ms-2">
        <div class="desc">Card payment for pay later fee was declined. Please update to continue.</div>
        <div class="sub-desc mt-1">Update card</div>
      </div>
    </div>
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="body">
    <div class="gray-box p-3">
      @for (item of items; track item; let i = $index) {
      <div class="gray-box-item d-flex justify-content-between" [ngClass]="{'mt-2': i> 0}">
        <div class="gray-box-item-name-wrapper">
          <div class="name">{{item.name}}</div>
          <div class="description mt-1">{{item.description}}</div>
        </div>
        <div class="gray-box-item-amount">${{item.amount}}</div>
      </div>
      }

      <hr class="my-3" />

      <div class="gray-box-item total d-flex justify-content-between">
        <div class="">Total</div>
        <div>${{total}}</div>
      </div>
    </div>
    <app-stripe-form [btnTxt]="stripeFormBtnTxt" (onSubmit)="onStripeFormSubmit($event)"></app-stripe-form>
  </div>
</app-modal>
