<app-modal #modal [modalConfig]="modalConfig">
  <h1 class="fw-bold">Block and report</h1>
  <div class="mb-2">Let us know what's going on</div>
  <div class="item-container">
    @for (data of buttons; track data) {
    <div>
      <button class="select-option p-2 m-2" [ngClass]="{ 'selected': data.active }" (click)="toggleData(data)">
        {{data.reason}}
      </button>
    </div>
    }
  </div>
  <button class="btn-primary w-100 mt-3" [disabled]="submitDisabled" (click)="sendData()">Send to us</button>
</app-modal>
