<div>
  @if (showError) {
  <div>
    <div class="subtitle left">Verify your business manually</div>
    <div class="header-error">
      We could not locate a businesses in your legal name. Please fill out these details about your business
    </div>
  </div>
  } @else {
  <div class="subtitle left">Your business information</div>
  <div class="description left">All fields are required unless stated otherwise</div>
  }
  <form [formGroup]="form" (submit)="onVerify()" class="d-flex flex-column" style="margin-top: 24px">
    <div class="form-group">
      <label for="firstName">Business name</label>
      <input id="businessName" type="text" formControlName="businessName" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.businessName.errors }" />
      @if (submitted && f.businessName.errors) {
      <div class="invalid-feedback">
        @if (f.businessName.errors.required) {
        <div>Business name is required</div>
        }
      </div>
      }
    </div>
    <div class="form-group">
      <label for="postal">EIN</label>
      <input id="postal" type="text" class="form-control" formControlName="taxId"
        [ngClass]="{ 'is-invalid': submitted && f.taxId.errors }" />
      @if (submitted && f.taxId.errors) {
      <div class="invalid-feedback">
        @if (f.taxId.errors.required) {
        <div>EIN is required</div>
        }
      </div>
      }
    </div>
    @if(isFullAddressVisible) {
    <div class="form-group">
      <label for="firstName">Business address</label>
      <app-places-autocomplete-input placeholder="Enter full address" elementId="location" #autocompleteInput
        (onChangeEvent)="updateLocation($event)" [(inputLocation)]="location"></app-places-autocomplete-input>
    </div>
    } @else {
    <div class="form-group">
      <label for="firstName">Address Line 1</label>
      <input id="address" type="text" formControlName="address" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.address.errors }" placeholder="123 Main St" />
      @if (submitted && f.address.errors) {
      <div class="invalid-feedback">
        @if (f.address.errors.required) {
        <div>Address Line 1 is required</div>
        }
      </div>
      }
    </div>
    <div class="form-group">
      <label for="firstName">Address Line 2</label>
      <input id="extendedAddress" type="text" formControlName="extendedAddress" class="form-control"
        placeholder="Apt, suite, unit, building, floor, etc."
        [ngClass]="{ 'is-invalid': submitted && f.extendedAddress.errors }" />
    </div>
    <div>
      <div class="d-flex" style="gap: 8px">
        <div class="form-group" style="flex: 1">
          <label for="city">City</label>
          <input id="city" type="text" formControlName="city" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
          @if (submitted && f.city.errors) {
          <div class="invalid-feedback">
            @if (f.city.errors.required) {
            <div>City is required</div>
            }
          </div>
          }
        </div>
        <div class="form-group" style="flex: 1">
          <label for="region">State</label>
          <select formControlName="region" class="form-control form-control-select"
            [ngClass]="{'is-invalid': submitted && f.region.errors}">
            @for (state of states; track state) {
            <option [value]="state.abbreviation">{{state.abbreviation}}</option>
            }
          </select>
          @if (submitted && f.region.errors) {
          <div class="invalid-feedback">
            @if (f.region.errors.required) {
            <div>State is required</div>
            }
          </div>
          }
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="postal">Zip code</label>
      <input id="postal" type="text" class="form-control" formControlName="postalCode"
        [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" />
      @if (submitted && f.postalCode.errors) {
      <div class="invalid-feedback">
        @if (f.postalCode.errors.required) {
        <div>Zip code is required</div>
        }
      </div>
      }
    </div>
    }
    <button [disabled]="isFullAddressVisible" type="submit" class="btn btn-primary w-100">Submit for review</button>
  </form>
</div>