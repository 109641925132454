<app-modal #modal [modalConfig]="modalConfig">
  @if (!showSucessUi) {
  <div class="header">{{headerText}}</div>
  } @if (!showSucessUi) {
  <div class="body">
    @if (listing) {
    <div class="listing-details-header d-flex align-items-center">
      <div class="listing-image">
        <app-listing-image [src]="listing.mainImg" [width]="65.75" [height]="52"></app-listing-image>
      </div>
      <div class="listing-details">
        <div class="listing-main-text">{{listing.RegistrationYear}} {{listing.CarMake}} {{listing.CarModel}}</div>
        <div class="listing-sub-text">
          <div class="d-inline-block after-dot">${{ (listing.Price || 0) | number }}</div>
          <div class="d-inline-block">{{ (listing.Mileage || 0) | number }} miles</div>
        </div>
      </div>
    </div>
    } @if (!isListingNotOwnedByUser) {
    <div>
      <div class="benefits-title my-3">Get these powerful features/tools:</div>
      <div class="text-justify">
        <ul class="benefits-list">
          @for (feature of features2; track feature; let i = $index) {
          <li>
            <span class="requirement">{{ feature.text }}</span>
          </li>
          }
        </ul>
      </div>
    </div>
    } @if (paymentOption) {
    <form class="pay-from mt-4" [formGroup]="form">
      <div class="total-panel mb-4 p-3">
        <div class="top">
          <div class="renew d-flex justify-content-between top-row">
            <div class="d-flex flex-column">
              <span class="pay-label-title">{{paymentDescription}}</span>
            </div>
            <div class="pay-label-title">{{ payAmount | currency }}</div>
          </div>
          @if (payData.promoCode) {
          <div class="discount d-flex justify-content-between top-row">
            <div>
              <span> Discount </span>
              <span class="discount-hash-tag">
                #{{ payData.promoCode }}
                <span class="remove-discount" (click)="deletePromoCode()"> X </span>
              </span>
            </div>
            <div class="discount-value">{{ -1 * discount | currency }}</div>
          </div>
          }
        </div>
        <hr />
        @if (paymentOption === 'now') {
        <div class="total d-flex justify-content-between fw-bold">
          <div>Total today</div>
          <div>{{ (payAmount - discount) | currency }}</div>
        </div>
        <hr />
        <div class="total d-flex justify-content-between fw-bold">
          <div class="d-flex flex-column">
            <span (click)="onViewPricing()" class="closing-fee">+ <u>Closing fee</u></span>
            <span class="pay-label-subtitle">Fee processed only if vehicle sells</span>
          </div>
          <div class="closing-fee-amount">
            @if (closingFeeDiscount > 0) {
            <span><s>{{ closingFee | currency }}</s></span>
            }
            <span>{{ (closingFee - closingFeeDiscount) | currency }}</span>
          </div>
        </div>
        } @else { @if (discount > 0) {
        <div>
          <div class="d-flex justify-content-between">
            <span class="pay-label-title">Pay later total</span>
            <div class="pay-label-title">{{ (payAmount - discount) | currency }}</div>
          </div>
          <hr />
        </div>
        }
        <div class="total d-flex justify-content-between fw-bold">
          <div class="d-flex flex-column">
            <span class="total-later">Total today</span>
            <span class="pay-label-subtitle"
              >After your vehicle is sold, {{ (payAmount - discount) | currency }} is processed</span
            >
          </div>
          <div>0</div>
        </div>
        }
      </div>
      @if (!hasDiscount) {
      <div class="mb-4 ms-3">
        <span (click)="addDiscount()" class="have-discount">Have a discount code?</span>
      </div>
      } @if (!payData.promoCode && hasDiscount) {
      <div class="d-flex align-items-center form-group mb-4">
        <label class="form-label" for="promoCode"></label>
        <input
          type="text"
          class="form-control flex-fill me-sm-2"
          id="promoCode"
          placeholder="Discount code"
          formControlName="temporaryPromoCode"
        />
        @if (applyPromocodeLoading) {
        <div class="spinner-border me-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        } @if (!applyPromocodeLoading) {
        <button type="submit" class="btn btn-outline-primary apply-button" (click)="applyPromoCode()">Apply</button>
        }
      </div>
      }
      <div class="payment-method-header">Payment method</div>
      @if (hasExistingPaymentMethod) {
      <div class="py-4">
        <app-payment-method-selector
          name="paymentMethod"
          formControlName="paymentMethod"
          [paymentMethods]="paymentMethods"
          (onAddSuccess)="onAddPaymentMethodSuccess($event)"
        ></app-payment-method-selector>
      </div>
      } @if (!hasExistingPaymentMethod) {
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
      }
      <div class="payment-terms">{{displayTerms}}</div>
      @if (!payLoading) {
      <button class="btn btn-primary w-100 pay-button mb-4" (click)="pay()">
        <span> Pay {{ (payAmount - discount) | currency }}</span>
      </button>
      } @if (payLoading) {
      <div class="w-100 text-center">
        <div class="spinner-border me-2 text-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      }
    </form>
    }
  </div>
  }
</app-modal>

<app-modal-view-pricing [scrollToClosingHeader]="true" [(isOpen)]="showPricingModal"></app-modal-view-pricing>
