import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import {
  DOCUMENT_FILE_SIZE,
  MultipleFileUploadResult,
  ProcessInvitationData,
  SellerListingService,
  SellerService,
  UploadService,
} from 'src/app/core/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISellerListing } from 'src/app/models';
import { ALLOWED_REGISTERED_YEARS } from '../modal-add-vehicle-buyer/modal-add-vehicle-buyer.component';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import STATES from 'src/app/statics/states-hash';
import { OptionsSlideItem } from '../options-slide/options-slide.component';
import { convertDecodedVinInfoToListing } from 'src/app/shared/utils-listing';

const LICENSE_PLATE: OptionsSlideItem = { text: 'License plate', value: 'licensePlate' };
const VIN_OPTION: OptionsSlideItem = { text: 'VIN', value: 'vin' };
@Component({
  selector: 'app-modal-confirm-vehicle-details',
  templateUrl: './modal-confirm-vehicle-details.component.html',
  styleUrls: ['./modal-confirm-vehicle-details.component.scss'],
})
export class ModalConfirmVehicleDetailsComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;

  @Input() isOpen = false;

  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onConfirmDetails = new EventEmitter<string>();
  @Output() onUpdatedVin = new EventEmitter();

  @Input() dealNow: ProcessInvitationData;
  @Input() updateVin: boolean;

  @Output() onClose = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {};
  form: UntypedFormGroup;
  editListing = false;
  stateOptions = STATES.map((state) => state.name);
  showVin = false;
  selectedOption = VIN_OPTION;
  inputOptions: OptionsSlideItem[] = [VIN_OPTION, LICENSE_PLATE];
  states = STATES;
  notificationService: any;
  manualEntry: boolean;
  reviewDetails = false;
  showModalVintageVehicle = false;
  formInvalid = false;
  photoUploaded: any;
  images = [];
  notNeedReview = false;
  isBoatConfirm = false;

  get f() {
    return this.form.controls;
  }

  @ViewChild('yearField', { static: true }) yearField: NgbTypeahead;
  years: string[] = ALLOWED_REGISTERED_YEARS;
  focusYear$ = new Subject<string>();
  clickYear$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickYear$.pipe(filter(() => !this.yearField.isPopupOpen()));
    const inputFocus$ = this.focusYear$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((input) => {
        return this.years;
      })
    );
  };

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly sellerListingService: SellerListingService,
    private readonly sellerService: SellerService,
    private readonly uploadService: UploadService
  ) {
    this.form = fb.group({
      vin: [null, [Validators.required]],
      mainImg: [null],
      yearField: [null, [Validators.required]],
      make: [null, [Validators.required]],
      model: [null, [Validators.required]],
      listingId: [null, [Validators.required]],
      stateRegistered: [null],
      vehicleType: [null],
      licensePlate: [null],
    });
  }

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Confirm vehicle modal',
      backOption: false,
      beforeClose: async () => {
        this.isOpen = false;
        this.onClose.emit();
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen, dealNow, updateVin } = changes;

    if (isOpen?.currentValue) {
      this.modalComponent.open();

      if (this.updateVin) {
        this.manualEntry = true;
        this.notNeedReview = true;
        this.reviewDetails = false;
      }
    }

    if (updateVin?.currentValue) {
      this.updateVin = updateVin.currentValue;
    }

    if (dealNow?.currentValue?.listing) {
      this.setFormValues(this.dealNow.listing);
    }
  }

  setFormValues(listing: ISellerListing) {
    const {
      vinNumber,
      uploadImages,
      vehicleType,
      RegistrationYear,
      CarMake,
      CarModel,
      _id,
      licencePlate,
      stateRegistered,
      Mileage,
    } = listing;
    this.form.patchValue({
      vin: vinNumber,
      vehicleType,
      yearField: RegistrationYear,
      make: CarMake,
      model: CarModel,
      listingId: _id,
      licencePlate,
      stateRegistered,
      miles: Mileage,
      mainImg: uploadImages[0]?.images,
    });

    this.isBoatConfirm = listing.vehicleType === 'Boat';

    if (vinNumber) {
      this.reviewDetails = true;
    }

    if (!vinNumber) {
      this.showVin = true;
    }
  }

  onEditClick() {
    this.editListing = true;
  }

  confirm() {
    if (this.form.invalid) {
      this.formInvalid = true;
      return;
    }

    const { vin, yearField, make, model, licencePlate, stateRegistered, miles, BodyStyle, hours, registrationNumber } =
      this.form.value;
    this.dealNow.listing = {
      ...this.dealNow.listing,
      BodyStyle: BodyStyle === 'Car' ? 'Sedan' : BodyStyle,
      vinNumber: vin,
      RegistrationYear: yearField,
      CarMake: make,
      CarModel: model,
      licencePlate: licencePlate,
      stateRegistered,
      Mileage: miles ?? hours,
      additionalDetails: {
        boat: {
          registrationNumber,
        },
      },
      ...(this.images.length > 0 && { uploadImages: this.images }),
    };

    this.sellerListingService.updateSellerListing(this.dealNow.listing).subscribe((results) => {
      if (!this.updateVin) {
        this.onConfirmDetails.emit(this.dealNow._id);
      } else {
        this.onUpdatedVin.emit(results.data);
      }
      this.modalComponent.close();
    });
  }
  getInfoByLicensePlate() {
    if (this.form.controls.licensePlate.value) {
      const licensePlate = this.form.controls.licensePlate.value;

      this.sellerService
        .getInfoByPlate(licensePlate, this.form.controls.stateRegistered.value)
        .pipe(
          catchError((error) => {
            this.showModalVintageVehicle = true;
            throw error;
          })
        )
        .subscribe((res: { data: any; message: string; infoData: any; trimOptions; vinNumber: string }) => {
          const { vinNumber, data } = res;
          const vinfinalNmber = vinNumber || data.vin;
          const { make, model, year } = data.attributes;
          this.form.patchValue({
            vin: vinfinalNmber,
            year,
            make,
            model,
          });
          this.reviewDetails = true;
        });
    } else {
      this.notificationService.notification(
        'error',
        'Please input licence plate or vin to grab your vehicle information.'
      );
    }
  }

  lookupVehicle() {
    if (this.selectedOption === LICENSE_PLATE) {
      this.getInfoByLicensePlate();
      return;
    }
    const { vin, vehicleType } = this.form.value;
    this.sellerService
      .getInfoByVIN(vin, vehicleType)
      .pipe(
        map((res) => convertDecodedVinInfoToListing(res)),
        map((res) => {
          const {
            RegistrationYear,
            CarMake,
            CarModel,
            mpg,
            DriveType,
            Cylinders,
            Doors,
            Transmission,
            Fuel,
            BodyStyle,
            Engine,
          } = res.listing;

          return {
            vehicleType,
            vinNumber: vin,
            RegistrationYear,
            CarMake,
            CarModel,
            mpg,
            DriveType,
            Cylinders,
            Doors,
            Transmission,
            Fuel,
            BodyStyle,
            Engine,
            disableUserId: true,
          };
        }),
        catchError((error) => {
          this.notificationService.notification('error', 'Details could not be found');
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (listing) => {
          this.form.patchValue({
            vin,
            year: listing.RegistrationYear,
            make: listing.CarMake,
            model: listing.CarModel,
            vehicleType: listing.vehicleType,
          });
          this.reviewDetails = true;
        },
        error: (error) => {
          this.showModalVintageVehicle = true;
          // You can also handle errors that might occur during subscription here,
          // but the catchError above should handle most cases.
        },
      });
  }

  onVintageVehicleSave(event) {
    this.manualEntry = true;
  }

  edit() {
    this.reviewDetails = false;
    this.manualEntry = true;
  }

  onFilesUpload(files: File[], dir: string): Observable<MultipleFileUploadResult> {
    if (!files || !files.length) {
      return throwError('Please select a file.');
    }

    if (files.some((file) => file.size > DOCUMENT_FILE_SIZE)) {
      return throwError('Maximum file size is 20mb only.');
    }
    const formData: FormData = new FormData();
    files.forEach((file: any) => {
      if (file instanceof File) {
        formData.append('uploads', file, file.name);
      } else if (file instanceof Blob) {
        const convertedFile = new File([file], 'blobfile', { type: file.type });
        formData.append('uploads', convertedFile, convertedFile.name);
      }
    });

    return this.uploadService.uploadMultipleFiles(formData, dir);
  }
  onPhotosUpload(listingId: string) {
    if (!this.photoUploaded) {
      this.notificationService.notification('error', 'No file selected.');
      return;
    }

    const dir = `listings/${listingId}/photos/`;
    this.onFilesUpload(this.photoUploaded, dir).subscribe({
      next: ({ files: images }) => {
        const isPrimaryStatus = !this.images?.length;

        const newImages = images.map((img, i) => {
          const { path } = img;
          const primaryStatus = i === 0 ? isPrimaryStatus : false;

          return {
            images: path,
            primaryStatus,
          };
        });
        this.images = [...newImages];
      },
      error: (error) => {
        console.error('File upload failed:', error);
        this.notificationService.notification('error', 'File upload failed.');
      },
    });
  }

  async onFileSelect(event): Promise<void> {
    const eventTarget = event.target;
    const { files } = eventTarget;

    const rawFileList = Array.from(files)?.map((file: File) => this.uploadService.validateImage(file));
    const fileList = await Promise.all(rawFileList);

    if (!fileList || fileList.length === 0) {
      return;
    }

    this.photoUploaded = fileList;

    const firstFile = fileList[0];

    const isValid = await this.uploadService.validateImage(firstFile);

    if (isValid) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const base64Image = e.target.result as string;
        this.form.controls.mainImg.setValue(base64Image);
      };
      reader.readAsDataURL(firstFile);
      this.onPhotosUpload(this.dealNow.listing._id);
    }
  }

  boatManual(setManual: boolean) {
    this.manualEntry = setManual;

    this.modalConfig = {
      name: 'Confirm vehicle modal',
      hideLogo: true,
      backOption: setManual,
      onBackFunction: () => {
        this.boatManual(false);
      },
      beforeClose: async () => {
        this.isOpen = false;
        this.onClose.emit();
        return true;
      },
    };
  }
}
